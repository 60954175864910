import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import { IBanner } from '../models/banner.model';
import { getCdnStaticUrl } from '@features/common/store/common.selectors';
import classNames from 'classnames/dedupe';
import { isMobile } from 'react-device-detect';
import HolidayBannerStars from '@features/common/components/HolidayBannerStars';

const ChristmasBanner = (props: IBanner) => {
	const { buttonText, description, title } = props;
	const cdnStatic = useSelector(getCdnStaticUrl);

	return (
		<div className='w-full relative z-10'>
			<div className='aspect-w-4 aspect-h-1 hidden lg:block'>
				<Image
					alt='holidayBannerDesktop'
					unoptimized={true}
					src={`${cdnStatic}/Christmas_Desktop.svg`}
					layout='fill'
					className='contain'
					objectFit='cover'
					priority={true}
				/>
			</div>
			<div className='aspect-w-2 aspect-h-1 lg:hidden sm:block hidden'>
				<Image
					alt='holidayBannerTablet'
					unoptimized={true}
					src={`${cdnStatic}/Christmas_Tablet.svg`}
					layout='fill'
					className='contain'
					objectFit='cover'
					priority={true}
				/>
			</div>
			<div className='aspect-w-1 aspect-h-1 sm:hidden block'>
				<Image
					alt='holidayBannerMobile'
					unoptimized={true}
					src={`${cdnStatic}/Christmas_Mobile.svg`}
					layout='fill'
					objectFit='cover'
					className='contain'
					priority={true}
				/>
			</div>
			<div className='flex items-center justify-center flex-col absolute inset-0 w-full text-center text-white'>
				<div className='flex items-center flex-col xl:w-1/2 lg:w-[60%] sm:w-[73%] w-[76%] 2xs:w-[90%]'>
					<p
						className='font-bold lg:leading-[2.25rem] text-1.5xl leading-[1.875rem] 2xs:text-lg xs:text-2xl sm:mt-[15px] sm:text-2xl lg:text-[1.875rem] xl:text-[2.1875rem] xl:leading-[2.25rem]'
						style={{ fontFamily: 'Montserrat' }}
					>
						{title}
					</p>
					<p
						className='font-normal text-base leading-[1.1875rem] xl:mt-[1.875rem] xl:mb-13 lg:mt-5 lg:mb-5 sm:mt-[1.875rem] mt-5 mb-[1.875rem] 2xs:text-sm 2xs:mt-5 sm:text-base lg:text-xbase'
						style={{ fontFamily: 'Montserrat' }}
					>
						{description}
					</p>

					<div className='flex sm:flex-row flex-row-reverse w-full items-center justify-center'>
						<div className='hidden relative lg:block'>
							<svg
								className='absolute top-[-2.5rem] left-[-4.6875rem]'
								width='53'
								height='50'
								viewBox='0 0 53 50'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M4.31108 1.91867C4.02942 -0.228233 0.708704 0.186801 0.996133 2.3358C1.42966 5.64233 3.05164 8.26392 4.3303 11.2733C5.34971 13.669 6.36758 16.051 7.68823 18.2999C10.3556 22.8337 13.8484 26.8198 17.886 30.1694C22.3763 33.8876 27.4541 36.7086 32.8064 38.9723C36.9208 40.713 41.4515 41.3103 45.6248 42.7635C42.9763 44.1989 40.1438 45.2255 37.1264 45.6852C34.9998 46.0089 35.3898 49.3456 37.5243 49.0181C42.75 48.225 47.5503 45.9491 51.7682 42.7645C52.4252 42.2674 52.5017 41.5195 52.3062 40.7845C51.6326 38.2451 49.2704 36.6359 47.6363 34.7462C45.796 32.617 44.6204 30.0593 42.6114 28.0528C41.0849 26.5309 39.0439 29.1997 40.5438 30.7054C42.3379 32.4956 43.2429 34.9548 44.9698 36.7864C45.948 37.826 47.2782 38.9018 48.2155 40.1435C43.9882 38.48 39.3354 38.075 35.1195 36.2907C30.3936 34.2946 25.6884 32.0627 21.6326 28.8708C17.4555 25.5821 13.5687 21.7287 10.8497 17.1039C9.49569 14.8033 8.55289 12.3763 7.41715 9.97774C6.16991 7.34792 4.6938 4.84515 4.30743 1.91076L4.31108 1.91867Z'
									fill='#FF652E'
								/>
							</svg>
						</div>
						<div className='lg:hidden sm:block hidden relative'>
							<svg
								className='absolute top-[-1.875rem] left-[-3.4375rem]'
								width='34'
								height='32'
								viewBox='0 0 34 32'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M2.25525 1.23943C2.07478 -0.149093 -0.0686061 0.121391 0.115585 1.51127C0.393361 3.64979 1.43856 5.34454 2.26195 7.29033C2.9184 8.83933 3.57387 10.3795 4.42481 11.8334C6.14354 14.7643 8.3953 17.3406 10.999 19.5048C13.8948 21.9071 17.1701 23.7287 20.6231 25.1897C23.2774 26.3132 26.201 26.6967 28.8936 27.6342C27.1833 28.5642 25.3547 29.23 23.407 29.5292C22.0344 29.7399 22.284 31.898 23.6618 31.6848C27.0349 31.1686 30.1342 29.6936 32.8583 27.6311C33.2827 27.3091 33.3325 26.8253 33.2068 26.35C32.7736 24.7078 31.25 23.6684 30.1966 22.4471C29.0102 21.071 28.253 19.4172 26.9577 18.1206C25.9734 17.1371 24.6546 18.8646 25.6217 19.8376C26.7785 20.9945 27.361 22.5847 28.4744 23.7683C29.1051 24.4402 29.963 25.1353 30.5671 25.9379C27.8399 24.8645 24.8373 24.6053 22.1175 23.4537C19.0688 22.1654 16.0334 20.7247 13.4178 18.6625C10.724 16.5378 8.21791 14.0476 6.46592 11.0578C5.59345 9.57053 4.98647 8.00125 4.25494 6.45044C3.4516 4.75014 2.50046 3.13215 2.2529 1.23432L2.25525 1.23943Z'
									fill='#FF652E'
								/>
							</svg>
						</div>

						<div className='sm:hidden block relative'>
							<svg
								className='absolute top-[-2.8125rem] left-[1.25rem]'
								width='28'
								height='39'
								viewBox='0 0 28 39'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M25.4564 1.69429C25.2965 0.237849 27.5474 -0.025909 27.7039 1.43284C27.9505 3.67592 27.3051 5.66087 26.948 7.84521C26.6627 9.58435 26.3762 11.3142 25.8692 13.0056C24.8437 16.4161 23.1873 19.6001 21.0702 22.4538C18.7146 25.6229 15.8283 28.2972 12.6717 30.6496C10.2453 32.4584 7.36133 33.5829 4.84995 35.2123C6.82287 35.7276 8.85101 35.9447 10.9096 35.7593C12.3605 35.6285 12.6405 37.8843 11.1839 38.014C7.61908 38.3371 4.09575 37.6169 0.8093 36.2054C0.297199 35.9849 0.1266 35.5058 0.136992 34.9911C0.171742 33.2134 1.46677 31.7742 2.23767 30.2683C3.1058 28.5717 3.46781 26.7008 4.46655 25.0576C5.22592 23.8107 6.99754 25.2349 6.2531 26.4668C5.36087 27.9332 5.16106 29.6956 4.31967 31.1783C3.84346 32.0196 3.14146 32.9415 2.72466 33.909C5.2377 32.1327 8.23295 31.1148 10.7191 29.261C13.5065 27.1856 16.2426 24.9587 18.397 22.2057C20.6157 19.3695 22.5526 16.2091 23.5974 12.7304C24.1181 10.9997 24.3479 9.25235 24.7093 7.49251C25.1068 5.5627 25.6753 3.6794 25.4575 1.6885L25.4564 1.69429Z'
									fill='#FF652E'
								/>
							</svg>
						</div>

						<Link href={'#scrollOnGamys'} scroll={true}>
							<a className='block'>
								<button
									style={{ padding: '1.125rem 1.75rem', fontFamily: 'Inter' }}
									className={classNames(
										'rounded-2xl border-solid border-[0.0625rem] border-white h-14 w-[10.5625rem] flex justify-center items-center font-bold text-base leading-5 transition-all',
										{
											'hover:bg-white hover:text-[#AD310B]': !isMobile,
											'active:bg-white active:text-[#AD310B] christmasBanner': true,
										}
									)}
									type='button'
									data-testid='bannerSectionPlayNow'
									tabIndex={1}
								>
									{buttonText}
								</button>
							</a>
						</Link>
					</div>
				</div>
			</div>

			<HolidayBannerStars />
		</div>
	);
};
export default ChristmasBanner;
