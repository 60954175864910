import { SpecialNotification } from '@features/auth/models/special-notification.model';
import { changeSpecialNotifications } from '@features/auth/store/auth.actions';
import { updateChampionshipStatusModal, updateFirstSocialLoginStatus } from '@features/auth/store/auth.reducer';
import { getCurrentUser } from '@features/auth/store/auth.selectors';
import { parseError } from '@http/httpHelpers';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toggleSocialLoginNotificationsModal } from '../store/common.reducer';
import InputCheckbox from './InputCheckbox';
import PrimaryButton from './PrimaryButton';
const modalAlert = withReactContent(Swal);

type NamesOfNotifications =
	| 'notificationNewGamysWithRewards'
	| 'newsMarketingNotifications'
	| 'notificationNewChampionshipsWithRewards';

interface ICheckBoxes {
	label: string;
	name: NamesOfNotifications;
	id: string;
}

const checkBoxes: ICheckBoxes[] = [
	{
		label: 'bcs-Auth-Notification-NewGamysWithRewards',
		name: 'notificationNewGamysWithRewards',
		id: 'GAMYS_WITH_AWARDS',
	},
	{
		label: 'bcs-Auth-Notification-NewsChampionships',
		name: 'notificationNewChampionshipsWithRewards',
		id: 'CHAMPIONSHIP_NEWS',
	},
	{
		label: 'bcs-Auth-Notification-NewsAboutGamys',
		name: 'newsMarketingNotifications',
		id: 'GAMYS_NEWS',
	},
];

const SocialLoginNotificationsModal = () => {
	const dispatch = useAppDispatch();
	const { formatMessage } = useIntl();
	const isLoadingRef = useRef(false);

	const currentUser = useSelector(getCurrentUser);

	const { register, handleSubmit, getValues, formState, setError } = useForm({
		defaultValues: {
			notificationNewGamysWithRewards: true,
			newsMarketingNotifications: true,
			notificationNewChampionshipsWithRewards: true,
		},
		mode: 'onBlur',
	});

	const generateNotificationSiteNewSettings = useCallback((): SpecialNotification => {
		const values = getValues();

		return {
			notificationNewGamysWithRewards: values.notificationNewGamysWithRewards,
			newsMarketingNotifications: values.newsMarketingNotifications,
			notificationNewChampionshipsWithRewards: values.notificationNewChampionshipsWithRewards,
		};
	}, [getValues]);

	const onSubmitHandle = useCallback(() => {
		isLoadingRef.current = true;
		dispatch(changeSpecialNotifications(generateNotificationSiteNewSettings()))
			.then(unwrapResult)
			.then(() => {
				dispatch(toggleSocialLoginNotificationsModal(false));
				dispatch(updateFirstSocialLoginStatus(false));
				dispatch(updateChampionshipStatusModal(false));
				modalAlert.close();
			})
			.catch((error) => {
				const parsedError = parseError(error);
				setError('notificationNewGamysWithRewards', {
					message: parsedError.message,
				});
			})
			.finally(() => {
				isLoadingRef.current = false;
			});
	}, [dispatch, generateNotificationSiteNewSettings, setError]);

	const modal = useCallback(() => {
		modalAlert.fire({
			html: (
				<>
					<div className='flex justify-start'>
						<p
							className='font-semibold text-2xl text-dark-500 text-left'
							style={{ fontFamily: "'Montserrat', normal" }}
						>
							{formatMessage({ id: 'bcs-Auth-Notification-Title' })}
						</p>
					</div>
					<form
						className='flex flex-col items-center justify-center mt-7'
						data-testid='form'
						onSubmit={handleSubmit(onSubmitHandle)}
						id='social-login-form-after-success'
					>
						{checkBoxes.map((element, index) => (
							<div className='mb-4 mt-2 w-full text-left' key={index}>
								<InputCheckbox
									key={index}
									testId={element.name}
									id={element.name}
									label={formatMessage({ id: element.label })}
									tabIndex={index}
									fieldError={formState.errors.notificationNewGamysWithRewards}
									showError={true}
									hookRegister={register(`${element.name}` as NamesOfNotifications)}
								/>
							</div>
						))}
						<PrimaryButton
							type='submit'
							disabled={isLoadingRef.current}
							text={formatMessage({ id: 'bcs-Auth-Continue' })}
							tabIndex={2}
						/>
					</form>
				</>
			),
			showCancelButton: false,
			showCloseButton: false,
			showDenyButton: false,
			heightAuto: false,
			timer: undefined,
			position: 'center',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			customClass: {
				popup: 'alert-border-radius mt-[4%] !min-h-[200px]',
			},
			padding: '0px 0px 15px',
		});
	}, [formState.errors.notificationNewGamysWithRewards, formatMessage, handleSubmit, onSubmitHandle, register]);

	useEffect(() => {
		if (currentUser?.notificationNewGamysWithRewards === null) {
			dispatch(toggleSocialLoginNotificationsModal(true));
			modal();
		}
	}, [currentUser?.notificationNewGamysWithRewards, dispatch, modal]);

	return null;
};

export default SocialLoginNotificationsModal;
