import { getCurrentUser } from '@features/auth/store/auth.selectors';
import HeaderSection from '@features/layout/components/HeaderSection';
import StatisticsPlaceholder from '@features/statistics/components/StatisticsPlaceholder';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

type TournamentType = 'Prized' | 'All Tournaments' | 'Play now Gamys with awards' | 'Play now Gamys';

interface ITournamentPlaceholder {
	type: TournamentType;
}

const TournamentLeaderboardPlaceHolder = () => {
	const { formatMessage } = useIntl();

	const getBackgroundGradient = (): string => {
		return 'bg-gradient-to-r from-placeHolder-100 to-placeHolder-200';
	};

	const getStyleForElement = (index: number) => {
		if (index === 0) {
			return 'first:mt-[15px]';
		}

		if (index === 9) {
			return 'last:mb-[23px]';
		}
	};

	const getContainerAnimationStyles = () => {
		return 'overflow-hidden h-full w-full animate-shimmer bg-loading-pattern before:relative before:-translate-x-full before:content-none inset-y-0 right-2/4 isolate';
	};

	return (
		<div className='flex flex-col items-center md:items-start p-4 xl:px-[22px] lg:pr-[22px] lg:pl-0 md:pr-2.5'>
			<h1 className='text-xl text-left my-4 w-full'>
				{formatMessage({ id: 'bcs-Tournaments-WeeklyLeaderboard' })}
			</h1>
			<div
				className='shadow-lg rounded-3xl flex flex-col justify-start transition-shadow overflow-hidden bg-white w-full md:w-[16.313rem] h-[429px]'
				data-testid='tournament'
			>
				{Array.from(Array(10)).map((_, index) => (
					<div className={`flex items-center px-4 mb-[20px] ${getStyleForElement(index)}`} key={index}>
						<div
							className={`mr-[10px] rounded-full w-[22px] h-[22px] ${getBackgroundGradient()} relative overflow-hidden`}
						>
							<div className={`absolute ${getContainerAnimationStyles()}`} />
						</div>

						<span className={`md:w-[190px] h-[15px] w-full ${getBackgroundGradient()}`} />
					</div>
				))}
			</div>
		</div>
	);
};

const chooseHeaderSection = (type: TournamentType) => {
	switch (type) {
		case 'Prized':
			return 'bcs-Tournaments-PrizedTournamentsTitle';
		case 'Play now Gamys':
			return 'bcs-Tournaments-Play-NowTitle';
		case 'Play now Gamys with awards':
			return 'bcs-Tournaments-Play-Now-With-AwardsTitle';
		default:
			return 'bcs-Tournaments-ListingTitle';
	}
};

const TournamentPlaceHolder = ({ size, type }: Record<'size' | 'type', number | TournamentType>) => {
	const numberOfTournaments = [...Array(size).keys()];

	const getContainerAnimationStyles = () => {
		return 'overflow-hidden h-full w-full animate-shimmer bg-loading-pattern before:relative before:-translate-x-full before:content-none inset-y-0 right-2/4 isolate';
	};

	const getDescriptionLinePlaceholderStyle = (index: number): string => {
		if (index === 2) {
			return 'h-2 mb-[63px]';
		}
		return 'h-2 mb-[5px]';
	};

	const getBackgroundGradient = (): string => {
		return 'bg-gradient-to-r from-placeHolder-100 to-placeHolder-200';
	};

	return (
		<>
			{numberOfTournaments.map((_, index) => (
				<div
					className='sm:w-[261px] sm:h-[429px] w-full h-[126px] rounded-xl sm:rounded-3xl sm:shadow-xl shadow-3xl flex flex-col
					 justify-start transition-shadow bg-white sm:m-0'
					data-testid='restTournaments'
					key={index}
				>
					<div className='flex-row sm:flex-col flex-1 h-full flex w-full cursor-pointer relative p-2 sm:p-0'>
						<div className='sm:rounded-t-3xl w-[170px] h-[110px] min-w-[170px] mt-0 sm:w-full sm:h-auto sm:aspect-h-9 aspect-w-14 aspect-w-1 bg-white overflow-hidden '>
							<div className={`${getBackgroundGradient()}`} />
							<div className={`absolute ${getContainerAnimationStyles()}`} />
						</div>
						<div className='flex flex-col justify-start flex-1 sm:w-full pt-[7px] sm:ml-4 ml-2'>
							<div
								className={`sm:w-[230px] w-full sm:h-[42px] h-[20px] sm:mb-[39px] mb-[43px] mr-[9px] ${getBackgroundGradient()}`}
							/>
							<div
								className={`sm:w-[230px] hidden sm:block ${getDescriptionLinePlaceholderStyle(
									0
								)} ${getBackgroundGradient()}`}
							/>
							<div
								className={`sm:w-[200px] hidden sm:block ${getDescriptionLinePlaceholderStyle(
									1
								)} ${getBackgroundGradient()}`}
							/>
							<div
								className={`sm:w-[150px] hidden sm:block ${getDescriptionLinePlaceholderStyle(
									2
								)} ${getBackgroundGradient()}`}
							/>
							<div className='flex flex-col sm:mt-0.5 sm:hidden'>
								<div className='sm:absolute sm:bottom-0 w-full pl-0 sm:left-0'>
									<div
										className={`s:w-1/2 w-full rounded-[10px] px-6 h-10 relative overflow-hidden ${getBackgroundGradient()}`}
									>
										<div className={`absolute ${getContainerAnimationStyles()}`} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='sm:flex justify-between items-center p-3 pt-2 hidden'>
						<div
							className={`sm:w-[230px] w-[97px] rounded-[10px] px-6 sm:h-13 h-10 relative overflow-hidden ${getBackgroundGradient()}`}
						>
							<div className={`absolute ${getContainerAnimationStyles()}`} />
						</div>
					</div>
				</div>
			))}
		</>
	);
};

const RestTournamentsPlaceholder = ({ type }: ITournamentPlaceholder) => {
	const { formatMessage } = useIntl();

	return (
		<div className='flex flex-col flex-1 sm:w-full pb-10'>
			<div className='flex flex-col justify-start h-full items-center w-full sm:items-start flex-1 mx-auto md:space-x-5'>
				<h2 className='text-xl text-left my-4 self-start md:ml-5'>
					{formatMessage({ id: chooseHeaderSection(type) })}
				</h2>
				<div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-8 gap-5 sm:w-max justify-items-center w-full'>
					<TournamentPlaceHolder type={type} size={4} />
				</div>
			</div>
		</div>
	);
};

const TournamentWrapperPlaceholder = () => {
	const currentUser = useSelector(getCurrentUser);
	return (
		<div className='mt-18 flex flex-col w-full h-full'>
			{!currentUser ? <HeaderSection /> : null}
			<div
				className='w-full h-auto pt-6 md:pb-10'
				style={{
					background: 'linear-gradient(180deg, #FFFFFF 0%, #DAE2E8 90%)',
				}}
			>
				<div className='flex flex-col md:flex-row justify-center'>
					<StatisticsPlaceholder />
					<TournamentLeaderboardPlaceHolder />
				</div>
			</div>

			<div className='sm:mx-auto ml-[10px] mr-3 py-10 space-y-3 sm:px-4'>
				<RestTournamentsPlaceholder type='Play now Gamys with awards' />
				<RestTournamentsPlaceholder type='Prized' />
				<RestTournamentsPlaceholder type='Play now Gamys' />
				<RestTournamentsPlaceholder type='All Tournaments' />
			</div>
		</div>
	);
};

export default TournamentWrapperPlaceholder;
