import { IApplicationState } from '@store/root.reducer';
import { createSelector } from '@store/store';
import { IStatisticsLoadingState } from '../models/statistics-loading-state.model';
import { IStatisticsState } from '../models/statistics-state.model';

const statisticsSelector = ({ statistics }: IApplicationState): IStatisticsState => statistics;

export const getStatisticsLoading = (loadingKey: keyof IStatisticsLoadingState) =>
	createSelector(statisticsSelector, ({ loadingState }) => loadingState[loadingKey]);

export const getAwardsCount = createSelector(statisticsSelector, ({ awardsGivenCount }) => awardsGivenCount);

export const getAwardsTotalValue = createSelector(statisticsSelector, ({ awardsTotalValue }) => awardsTotalValue);

export const getGamesWithAwardsCount = createSelector(
	statisticsSelector,
	({ gamesWithAwardsCount }) => gamesWithAwardsCount
);

export const getWinnersCount = createSelector(statisticsSelector, ({ winnersCount }) => winnersCount);
