import React from 'react';
import { useSelector } from 'react-redux';
import { getCdnUrl } from '@features/common/store/common.selectors';
import Image from 'next/image';
import { ITournamentLeaderboardParticipant } from '@features/tournaments/models/tournament-leaderboard-participant.model';
import TextFade from '@features/common/components/TextFade';

interface ITournamentLeaderboardProps {
	participant: ITournamentLeaderboardParticipant;
}

const TournamentLeaderboardParticipant = ({ participant }: ITournamentLeaderboardProps) => {
	const cdnUrl = useSelector(getCdnUrl);

	const avatarSize = '21px';

	return (
		<>
			<p className='w-[44px] text-center'>{participant.position}.</p>
			<div
				className='rounded-full w-5 h-5 overflow-hidden mr-2'
				style={{
					boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 3px',
				}}
			>
				<Image
					alt='avatar'
					unoptimized={true}
					src={`${cdnUrl}avatars/${participant.avatarId}.jpg`}
					priority={true}
					width={avatarSize}
					height={avatarSize}
				/>
			</div>
			<p className='flex-1 pl-2 overflow-hidden overflow-ellipsis whitespace-nowrap'>{participant.nickname}</p>
			<p className='w-auto pl-2 text-right text-gold-500 overflow-hidden'>
				<TextFade testId="participantPoints" from={0} to={participant.points} start={true} durationInSeconds={1} />
			</p>
		</>
	);
};

export default TournamentLeaderboardParticipant;
