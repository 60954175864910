interface ISnowFlakesAnimation {
	count: number;
	color: string;
}

const SnowFlakesAnimation = (props: ISnowFlakesAnimation) => {
	const { color, count } = props;

	return (
		<div id='snowflakes' className='snowflake w-full' aria-hidden='true'>
			{[...Array(count)].map((_, i) => (
				<div className='snowflake' style={{ color }} key={i}>
					<div className='inner'>❅</div>
				</div>
			))}
		</div>
	);
};

export default SnowFlakesAnimation;
