import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { emitHttpFetchPrizedTournaments } from '../store/tournaments.actions';
import {
	getTournamentsLoadingStates,
	getTournamentsPrized,
	getTournamentsPrizedCount,
} from '../store/tournaments.selectors';
import TournamentsListing from './TournamentsListing';

const TournamentsPrizedListing = () => {
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();
	const prizedTournaments = useSelector(getTournamentsPrized);
	const totalPrizedTournaments = useSelector(getTournamentsPrizedCount);
	const isLoadingPrized = useSelector(getTournamentsLoadingStates('fetchPrizedTournaments'));
	const initialTakeSizeRef = useRef(8);
	const [takeSize, setTakeSize] = useState(initialTakeSizeRef.current * 2);

	const loadMorePrizedTournaments = useCallback(() => {
		if (!isLoadingPrized && totalPrizedTournaments !== null) {
			dispatch(emitHttpFetchPrizedTournaments({ skip: 0, take: takeSize }))
				.then(unwrapResult)
				.then(() => setTakeSize((prevState) => prevState + initialTakeSizeRef.current));
		}
	}, [dispatch, isLoadingPrized, takeSize, totalPrizedTournaments]);

	return (
		<TournamentsListing
			tournaments={prizedTournaments}
			totalTournaments={totalPrizedTournaments}
			isTournamentsLoading={isLoadingPrized}
			skip={takeSize - initialTakeSizeRef.current}
			loadMore={loadMorePrizedTournaments}
			noTournamentsMessage={formatMessage({
				id: 'bcs-Tournaments-NoPrizedTournamentsMessage',
			})}
			tournamentTitle={formatMessage({
				id: 'bcs-Tournaments-PrizedTournamentsTitle',
			})}
			typeTournament={'defaultTournaments'}
		/>
	);
};

export default TournamentsPrizedListing;
