import React from 'react';
import PartnerBox from './PartnerBox';
import { IPartnerCompany } from '../models/partners-state.model';
import { useIntl } from 'react-intl';

const PartnerCompanyList = () => {
	const { formatMessage } = useIntl();

	const partnersCompanyLogos: IPartnerCompany[] = [
		{ partnerCompanyImage: 'partner_company_logo_1', urlLink: 'https://www.decathlon.bg/' },
		{ partnerCompanyImage: 'partner_company_logo_2', urlLink: 'https://giftcometrue.com/' },
		{ partnerCompanyImage: 'partner_company_logo_3', urlLink: 'https://www.emag.bg/' },
	];

	return (
		<div className='partners-list-wrap' data-testid='awardsPartnersSectionTitle'>
			<p className='partners-list-title' style={{ fontFamily: 'Roboto' }}>
				{formatMessage({ id: 'bcs-Partners-List-Title' })}
			</p>
			<div className='partners-list-items'>
				{partnersCompanyLogos.map((partner, index) => {
					return (
						<PartnerBox
							key={index}
							partnerCompanyImage={partner.partnerCompanyImage}
							urlLink={partner.urlLink}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default PartnerCompanyList;
