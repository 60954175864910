import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { interval } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';
import { getAllMerchants } from '../store/merchants.selectors';
import MerchantListingCard from './MerchantListingCard';
import classNames from 'classnames/dedupe';
import MerchantHeader from './MerchantHeader';

interface IMerchantCardsContainerProps {
	pageIndex: number;
}

interface IMerchantDotsProps extends Omit<IMerchantCardsContainerProps, 'pageIndex'> {
	onClickPageChange: (pageNumber: number) => void;
	currentPage: number;
	dots: number[];
}

const MerchantCardsContainer = (props: IMerchantCardsContainerProps) => {
	const { pageIndex } = props;
	const merchants = useSelector(getAllMerchants);

	const getPageTranslateX = () => {
		if (pageIndex === 0) {
			return 0;
		}
		return -pageIndex * 100;
	};

	return (
		<div
			className={classNames(`w-full lg:overflow-hidden sm:w-[640px] xmd:w-[900px] lg:w-[894px] xl:w-[1192px]`, {
				'lg:flex lg:justify-center': merchants.length < 4,
			})}
		>
			<div
				className='overflow-scroll flex lg:overflow-visible space-x-4 sm:space-x-8 lg:space-x-12 py-13 px-[18%] s:px-[25%] sm:px-8 duration-500'
				data-testid='merchantsContainer'
				style={{ transform: `translateX(${getPageTranslateX()}%)`, scrollSnapType: 'x' }}
			>
				{merchants.map((merchant) => (
					<MerchantListingCard merchant={merchant} key={merchant.id} />
				))}
			</div>
		</div>
	);
};

const MerchantsDots = (props: IMerchantDotsProps) => {
	const { onClickPageChange, dots, currentPage } = props;

	const onChangeIndex = (newPage: number) => {
		if (currentPage === newPage) {
			return;
		}

		onClickPageChange(newPage);
	};

	const getDotColor = (pageDot: number) => {
		if (pageDot === currentPage) {
			return 'bg-main-500';
		}

		return 'bg-[#D9D9D9]';
	};

	return (
		<>
			{dots.map((dot) => (
				<button
					data-testid={dot === currentPage ? 'currentDotBtn' : 'dotBtn'}
					className={`transition-colors duration-500 cursor-pointer mr-[10px] w-[15px] h-[15px] rounded-2xl ${getDotColor(
						dot
					)}`}
					key={dot}
					onClick={() => onChangeIndex(dot)}
				/>
			))}
		</>
	);
};

const MerchantsListing = () => {
	const [pageIndex, setPageIndex] = useState<number>(0);
	const [stopSlider, setStopSlider] = useState(false);
	const merchants = useSelector(getAllMerchants);
	const [dots, setDots] = useState<number[]>([]);
	const [itemsPerPage, setItemsPerPage] = useState(4);
	const [isDesktopMedia, setIsDesktopMedia] = useState<boolean>(false);
	const [isLaptopMedia, setIsLaptopMedia] = useState<boolean>(false);

	useEffect(() => {
		const showDesktopCountMedia = window.matchMedia('(min-width: 1280px)');
		const showLaptopCountMedia = window.matchMedia('(min-width: 1024px) and (max-width: 1279px)');

		const desktopMediaListener = (event: MediaQueryListEvent) => setIsDesktopMedia(event.matches);
		const middleMediaListener = (event: MediaQueryListEvent) => setIsLaptopMedia(event.matches);

		setIsDesktopMedia(showDesktopCountMedia.matches);
		setIsLaptopMedia(showLaptopCountMedia.matches);

		showDesktopCountMedia.addEventListener('change', desktopMediaListener);
		showLaptopCountMedia.addEventListener('change', middleMediaListener);

		if (isDesktopMedia && !isLaptopMedia) {
			setItemsPerPage(4);
		} else if (!isDesktopMedia && isLaptopMedia) {
			setItemsPerPage(3);
		} else {
			setItemsPerPage(2);
		}

		if (isLaptopMedia || isDesktopMedia || !isDesktopMedia || !isLaptopMedia) {
			setPageIndex(0);
		}

		return () => {
			showDesktopCountMedia.removeEventListener('change', desktopMediaListener);
			showLaptopCountMedia.removeEventListener('change', middleMediaListener);
		};
	}, [isDesktopMedia, isLaptopMedia, itemsPerPage]);

	useEffect(() => {
		const sizeOfArray = Math.ceil(merchants.length / itemsPerPage);
		setDots(new Array(sizeOfArray).fill(null).map((_, index) => index));
	}, [itemsPerPage, merchants.length]);

	useEffect(() => {
		if (itemsPerPage === 2) {
			return;
		}

		const subscription = interval(6000)
			.pipe(
				takeWhile(() => !stopSlider),
				tap(() => {
					if (dots.length - 1 > pageIndex) {
						setPageIndex(pageIndex + 1);
						return;
					}
					setPageIndex(0);
				})
			)
			.subscribe();

		return () => {
			subscription.unsubscribe();
		};
	}, [dots.length, itemsPerPage, pageIndex, stopSlider]);

	const onPageChange = (index: number) => {
		setPageIndex(index);
		setStopSlider(true);
	};

	return (
		<>
			<div
				className={classNames('flex flex-col justify-center w-full items-center', {
					'lg:mb-[100px] mb-[40px]': merchants.length > 0,
				})}
			>
				<MerchantHeader merchantsLength={merchants.length} />
				{merchants.length > 0 ? (
					<>
						<div className='flex flex-row items-center self-center w-full'>
							<div className='mx-auto w-full flex justify-center'>
								<MerchantCardsContainer pageIndex={pageIndex} />
							</div>
						</div>
						{dots.length > 1 ? (
							<div className='mx-auto justify-center hidden lg:block sm:mt-0 mt-[30px]'>
								<MerchantsDots onClickPageChange={onPageChange} dots={dots} currentPage={pageIndex} />
							</div>
						) : (
							<></>
						)}
					</>
				) : (
					<></>
				)}
			</div>
		</>
	);
};

export default MerchantsListing;
