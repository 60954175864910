import { animate } from 'framer-motion';
import { useEffect, useRef } from 'react';

interface IFaderProps {
	start: boolean;
	from: number;
	to: number;
	className?: string;
	testId?: string;
	durationInSeconds?: number;
}

const TextFade = (props: IFaderProps) => {
	const { from, to, start, className, durationInSeconds, testId } = props;
	const nodeRef = useRef<HTMLSpanElement | null>(null);

	useEffect(() => {
		if (!nodeRef.current) {
			return;
		}
		nodeRef.current.textContent = from.toFixed(0);
	}, [from]);

	useEffect(() => {
		if (!nodeRef.current || !start) {
			return;
		}
		const node = nodeRef.current;

		const controls = animate(from, to, {
			duration: durationInSeconds || 0.5,
			onUpdate(value: number) {
				node.textContent = value.toFixed(0).toString();
			},
			onComplete() {
				node.textContent = to.toFixed(0).toString();
			},
		});

		return () => controls.stop();
	}, [from, to, start, durationInSeconds]);

	return <span data-testid={testId || ''} className={className} ref={nodeRef} />;
};

export default TextFade;
