import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuIdv4 } from 'uuid';

interface IStatisticsPlaceholderItemsProps {
	count: number;
	className: string;
}

const StatisticsPlaceholderItems = (props: IStatisticsPlaceholderItemsProps) => {
	const itemsRef = useRef(new Array(props.count).fill(null).map(() => uuIdv4()));

	const getBackgroundGradient = (): string => {
		return 'bg-gradient-to-r from-placeHolder-100 to-placeHolder-200';
	};

	const getContainerAnimationStyles = () => {
		return 'overflow-hidden h-full w-full animate-shimmer bg-loading-pattern before:relative before:-translate-x-full before:content-none inset-y-0 right-2/4 isolate';
	};

	return (
		<div className={props.className}>
			{itemsRef.current.map((id) => (
				<div className='stats-container' key={id}>
					<div
						className={`sm:w-[75px] sm:h-[50px] w-[45px] h-[25px] mb-2.5 ${getBackgroundGradient()} relative overflow-hidden`}
					>
						<div className={`absolute ${getContainerAnimationStyles()}`} />
					</div>

					<div
						className={`xl:w-[230px] md:w-[170px] sm:w-[130px] sm:h-[30px] w-[165px] h-[15px] ${getBackgroundGradient()} relative overflow-hidden`}
					>
						<div className={`absolute ${getContainerAnimationStyles()}`} />
					</div>
				</div>
			))}
		</div>
	);
};

const StatisticsPlaceholder = () => {
	const { formatMessage } = useIntl();

	const textStyle = {
		fontFamily: 'Montserrat',
		letterSpacing: '-1px',
	};

	return (
		<div className='flex flex-col items-center justify-center sm:mb-[77px] mb-[34px] sm:mt-[70px] mt-[30px]'>
			<div
				className='text-center md:text-3xl sm:text-1.5xl text-2xsbase text-dark-800 font-bold px-5'
				style={textStyle}
			>
				<p>{formatMessage({ id: 'bcs-Statistics-Awards-Title' })}</p>
			</div>
			<StatisticsPlaceholderItems
				className='1xl:flex flex-col 1xl:flex-row justify-center items-center w-full px-5 md:hidden flex'
				count={4}
			/>
			<div className='md:flex px-5 1xl:hidden hidden'>
				<StatisticsPlaceholderItems
					className='md:flex md:flex-col md:justify-center md:items-center w-auto lg:min-w-[360px] lg:w-[360px] md:min-w-[220px] md:w-[220px]'
					count={2}
				/>
				<StatisticsPlaceholderItems
					className='md:flex md:flex-col md:justify-center md:items-center w-auto lg:min-w-[360px] lg:w-[360px] md:min-w-[220px] md:w-[220px]'
					count={2}
				/>
			</div>
		</div>
	);
};

export default StatisticsPlaceholder;
