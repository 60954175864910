import React from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { getCdnStaticUrl } from '@features/common/store/common.selectors';
import { IPartnerCompany } from '../models/partners-state.model';
import Link from 'next/link';

const PartnerBox = ({ partnerCompanyImage, urlLink }: IPartnerCompany) => {
	const cdnStatic = useSelector(getCdnStaticUrl);

	return (
		<div className='partner-box-wrap' data-testid='awardsPartnerBox'>
			<div className={'partner-box-content'}>
				<Link href={urlLink} scroll={false}>
					<a data-testid='partnerButton' target='_blank'>
						<Image
							unoptimized={true}
							src={`${cdnStatic}/${partnerCompanyImage}.svg`}
							priority={true}
							layout='fill'
							objectFit='contain'
							alt={'partnerCompany'}
							data-testid='awardsPartnerLogo'
						/>
					</a>
				</Link>
			</div>
		</div>
	);
};

export default PartnerBox;
