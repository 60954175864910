const HolidayBannerStars = () => {
	return (
		<div className='mt-[-0.0625rem]'>
			<div className='hidden lg:block'>
				<svg
					className='absolute right-0'
					width='168'
					height='127'
					viewBox='0 0 168 127'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='mask0_23592_57289' maskUnits='userSpaceOnUse' x='0' y='0' width='168' height='127'>
						<rect width='168' height='127' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_23592_57289)'>
						<path
							d='M112.213 114.636L114.987 115.086C115.354 115.144 115.73 115.053 116.029 114.826L118.266 113.125C119.247 112.382 120.629 113.212 120.435 114.427L119.985 117.202C119.928 117.568 120.018 117.944 120.245 118.244L121.947 120.481C122.69 121.462 121.86 122.843 120.644 122.65L117.869 122.2C117.503 122.142 117.127 122.233 116.828 122.46L114.591 124.161C113.609 124.904 112.228 124.075 112.421 122.859L112.871 120.084C112.929 119.718 112.839 119.342 112.611 119.042L110.91 116.805C110.167 115.824 110.997 114.443 112.213 114.636V114.636Z'
							fill='#AD310B'
						/>
						<path
							d='M117.207 70.9205L119.982 71.3707C120.348 71.4285 120.724 71.338 121.023 71.1107L123.26 69.4093C124.241 68.6663 125.623 69.4962 125.43 70.7119L124.979 73.4868C124.922 73.8529 125.012 74.229 125.24 74.5284L126.941 76.7654C127.684 77.7466 126.854 79.1281 125.638 78.9348L122.863 78.4847C122.497 78.4269 122.121 78.5174 121.822 78.7448L119.585 80.446C118.604 81.189 117.222 80.3592 117.415 79.1435L117.865 76.3686C117.923 76.0025 117.833 75.6264 117.605 75.327L115.904 73.0901C115.161 72.1088 115.991 70.7273 117.207 70.9205Z'
							fill='#AD310B'
						/>
						<path
							d='M72.1472 48.1833L74.6089 46.8288C74.9325 46.6511 75.1771 46.3427 75.2678 45.9811L75.9873 43.2649C76.3018 42.0774 77.9073 41.8815 78.5009 42.9558L79.8555 45.4175C80.0331 45.741 80.3415 45.9857 80.7031 46.0764L83.4193 46.7958C84.6068 47.1103 84.8027 48.7158 83.7284 49.3094L81.2667 50.664C80.9432 50.8417 80.6985 51.15 80.6078 51.5117L79.8884 54.2278C79.5739 55.4154 77.9683 55.6112 77.3748 54.5369L76.0202 52.0752C75.8426 51.7517 75.5342 51.5071 75.1725 51.4164L72.4564 50.6969C71.2688 50.3824 71.0729 48.7769 72.1472 48.1833Z'
							fill='#AD310B'
						/>
						<path
							d='M104.478 19.2437L107.99 16.3288C108.454 15.9446 108.745 15.3833 108.782 14.7758L109.09 10.2208C109.228 8.22749 111.721 7.42142 112.995 8.95897L115.91 12.471C116.295 12.935 116.856 13.2258 117.463 13.2637L122.018 13.5715C124.012 13.7098 124.818 16.2023 123.28 17.4768L119.768 20.3917C119.304 20.7759 119.013 21.3372 118.975 21.9447L118.668 26.4996C118.529 28.4929 116.037 29.299 114.762 27.7614L111.847 24.2494C111.463 23.7854 110.902 23.4946 110.294 23.4567L105.739 23.1489C103.746 23.0106 102.94 20.5182 104.478 19.2437Z'
							fill='#AD310B'
						/>
						<path
							d='M62.3903 2.38765L63.7415 2.62772C63.9225 2.66277 64.1044 2.61544 64.2519 2.50791L65.3534 1.69623C65.8357 1.33906 66.5048 1.75678 66.397 2.347L66.1569 3.69824C66.1218 3.87919 66.1692 4.06104 66.2767 4.20859L67.0884 5.31017C67.4456 5.79237 67.0278 6.46144 66.4376 6.3537L65.0864 6.11355C64.9055 6.0785 64.7236 6.1259 64.576 6.23344L63.4745 7.04511C62.9923 7.40229 62.3232 6.98456 62.4309 6.39435L62.6711 5.0431C62.7061 4.86215 62.6587 4.6803 62.5512 4.53275L61.7395 3.43118C61.3823 2.94898 61.8001 2.2799 62.3903 2.38765Z'
							fill='#AD310B'
						/>
						<path
							d='M146.949 31.9897L148.3 32.2298C148.481 32.2649 148.663 32.2175 148.811 32.1099L149.912 31.2983C150.394 30.9411 151.063 31.3589 150.956 31.9491L150.715 33.3003C150.68 33.4812 150.728 33.6631 150.835 33.8107L151.647 34.9122C152.004 35.3944 151.586 36.0635 150.996 35.9558L149.645 35.7156C149.464 35.6806 149.282 35.7279 149.135 35.8355L148.033 36.6472C147.551 37.0044 146.882 36.5866 146.99 35.9964L147.23 34.6452C147.265 34.4643 147.217 34.2823 147.11 34.1348L146.298 33.0333C145.941 32.5511 146.359 31.8819 146.949 31.9897Z'
							fill='#AD310B'
						/>
						<path
							d='M1.53096 25.6318L2.88214 25.8719C3.06309 25.907 3.245 25.8596 3.39255 25.752L4.49409 24.9403C4.97629 24.5832 5.64537 25.001 5.53762 25.5912L5.29751 26.9424C5.26246 27.1233 5.30982 27.3052 5.41735 27.4528L6.22906 28.5543C6.58624 29.0365 6.16846 29.7056 5.57825 29.5978L4.22704 29.3577C4.04609 29.3227 3.86421 29.37 3.71666 29.4776L2.61512 30.2892C2.13292 30.6464 1.46381 30.2287 1.57156 29.6385L1.8117 28.2872C1.84675 28.1063 1.79936 27.9244 1.69183 27.7769L0.880148 26.6753C0.522976 26.1931 0.94074 25.524 1.53096 25.6318Z'
							fill='#AD310B'
						/>
					</g>
				</svg>
			</div>

			<div className='lg:hidden sm:block hidden'>
				<svg
					className='absolute right-0'
					width='110'
					height='84'
					viewBox='0 0 110 84'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='mask0_23592_57291' maskUnits='userSpaceOnUse' x='0' y='0' width='110' height='84'>
						<rect width='110' height='84' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_23592_57291)'>
						<path
							d='M74.3838 76.2486L76.233 76.5486C76.477 76.5871 76.7276 76.5268 76.9271 76.3753L78.4179 75.2415C79.0717 74.7464 79.9924 75.2994 79.8636 76.1096L79.5636 77.9588C79.5251 78.2028 79.5854 78.4535 79.737 78.653L80.8707 80.1437C81.3659 80.7976 80.8129 81.7182 80.0027 81.5894L78.1535 81.2894C77.9095 81.2509 77.6588 81.3112 77.4593 81.4628L75.9686 82.5965C75.3147 83.0917 74.3941 82.5387 74.5228 81.7285L74.8228 79.8793C74.8613 79.6353 74.801 79.3846 74.6495 79.1851L73.5157 77.6944C73.0206 77.0405 73.5736 76.1198 74.3838 76.2486Z'
							fill='#AD310B'
						/>
						<path
							d='M77.7119 47.1163L79.5611 47.4163C79.8051 47.4548 80.0558 47.3945 80.2553 47.243L81.746 46.1092C82.3999 45.6141 83.3205 46.1671 83.1918 46.9773L82.8918 48.8265C82.8533 49.0705 82.9136 49.3211 83.0651 49.5206L84.1989 51.0113C84.694 51.6652 84.141 52.5859 83.3308 52.4571L81.4816 52.1571C81.2376 52.1186 80.987 52.1789 80.7875 52.3304L79.2967 53.4642C78.6429 53.9593 77.7222 53.4063 77.851 52.5962L78.1509 50.747C78.1895 50.503 78.1292 50.2523 77.9776 50.0528L76.8439 48.5621C76.3487 47.9082 76.9017 46.9875 77.7119 47.1163Z'
							fill='#AD310B'
						/>
						<path
							d='M47.6856 31.964L49.3261 31.0613C49.5417 30.943 49.7047 30.7374 49.7652 30.4964L50.2446 28.6863C50.4542 27.8949 51.5242 27.7644 51.9197 28.4803L52.8224 30.1208C52.9408 30.3364 53.1463 30.4995 53.3873 30.5599L55.1974 31.0394C55.9888 31.2489 56.1193 32.3189 55.4034 32.7144L53.7629 33.6172C53.5473 33.7356 53.3843 33.941 53.3238 34.182L52.8444 35.9921C52.6348 36.7835 51.5648 36.9141 51.1693 36.1981L50.2666 34.5576C50.1482 34.342 49.9427 34.179 49.7017 34.1186L47.8916 33.6391C47.1002 33.4295 46.9697 32.3596 47.6856 31.964Z'
							fill='#AD310B'
						/>
						<path
							d='M69.2307 12.6784L71.5711 10.7359C71.8803 10.4798 72.0741 10.1058 72.0994 9.70092L72.3045 6.66543C72.3966 5.33709 74.0577 4.79992 74.907 5.82456L76.8495 8.16504C77.1056 8.47423 77.4796 8.66804 77.8845 8.69328L80.9199 8.8984C82.2483 8.99053 82.7854 10.6516 81.7608 11.5009L79.4203 13.4434C79.1111 13.6995 78.9173 14.0735 78.8921 14.4784L78.687 17.5138C78.5949 18.8422 76.9338 19.3793 76.0845 18.3547L74.1419 16.0142C73.8859 15.705 73.5119 15.5112 73.107 15.486L70.0715 15.2809C68.7432 15.1887 68.206 13.5277 69.2307 12.6784Z'
							fill='#AD310B'
						/>
						<path
							d='M41.1836 1.44546L42.0841 1.60545C42.2047 1.6288 42.3259 1.59726 42.4242 1.5256L43.1583 0.98469C43.4796 0.746666 43.9255 1.02504 43.8537 1.41837L43.6937 2.31885C43.6703 2.43944 43.7019 2.56062 43.7736 2.65895L44.3145 3.39305C44.5525 3.7144 44.2741 4.16027 43.8808 4.08847L42.9803 3.92843C42.8598 3.90508 42.7385 3.93667 42.6402 4.00833L41.9061 4.54924C41.5848 4.78726 41.1389 4.50889 41.2107 4.11556L41.3707 3.21508C41.3941 3.09449 41.3625 2.9733 41.2908 2.87497L40.7499 2.14088C40.5119 1.81953 40.7903 1.37365 41.1836 1.44546Z'
							fill='#AD310B'
						/>
						<path
							d='M97.5333 21.1725L98.4337 21.3325C98.5543 21.3559 98.6755 21.3243 98.7738 21.2526L99.5079 20.7117C99.8292 20.4737 100.275 20.7521 100.203 21.1455L100.043 22.0459C100.02 22.1665 100.052 22.2877 100.123 22.386L100.664 23.1201C100.902 23.4414 100.624 23.8874 100.23 23.8156L99.3299 23.6555C99.2094 23.6322 99.0881 23.6637 98.9898 23.7354L98.2557 24.2763C97.9344 24.5143 97.4885 24.2359 97.5603 23.8426L97.7203 22.9422C97.7437 22.8216 97.7121 22.7003 97.6405 22.602L97.0995 21.868C96.8615 21.5466 97.1399 21.1007 97.5333 21.1725Z'
							fill='#AD310B'
						/>
						<path
							d='M0.625044 16.9357L1.52549 17.0957C1.64607 17.1191 1.7673 17.0875 1.86563 17.0158L2.5997 16.4749C2.92105 16.2369 3.36693 16.5153 3.29513 16.9086L3.13511 17.8091C3.11176 17.9297 3.14332 18.0509 3.21498 18.1492L3.75591 18.8833C3.99393 19.2046 3.71552 19.6505 3.3222 19.5787L2.42174 19.4187C2.30115 19.3953 2.17994 19.4269 2.08162 19.4985L1.34754 20.0395C1.0262 20.2775 0.5803 19.9991 0.652104 19.6058L0.812134 18.7053C0.83549 18.5847 0.803913 18.4635 0.732254 18.3652L0.191339 17.6311C-0.0466841 17.3097 0.231718 16.8639 0.625044 16.9357Z'
							fill='#AD310B'
						/>
					</g>
				</svg>
			</div>

			<div className='sm:hidden block'>
				<svg
					className='absolute right-0'
					width='80'
					height='59'
					viewBox='0 0 80 59'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<mask id='mask0_23592_57293' maskUnits='userSpaceOnUse' x='0' y='0' width='80' height='59'>
						<rect width='80' height='59' fill='#D9D9D9' />
					</mask>
					<g mask='url(#mask0_23592_57293)'>
						<path
							d='M52.5449 53.7777L53.8395 53.9877C54.0104 54.0147 54.1858 53.9725 54.3255 53.8664L55.3692 53.0726C55.827 52.726 56.4715 53.1132 56.3814 53.6804L56.1714 54.975C56.1444 55.1458 56.1866 55.3213 56.2927 55.461L57.0865 56.5047C57.4331 56.9625 57.0459 57.607 56.4787 57.5169L55.1841 57.3068C55.0133 57.2799 54.8378 57.3221 54.6981 57.4282L53.6544 58.2219C53.1966 58.5686 52.5521 58.1814 52.6422 57.6142L52.8523 56.3196C52.8792 56.1487 52.837 55.9733 52.7309 55.8336L51.9371 54.7899C51.5905 54.3321 51.9777 53.6875 52.5449 53.7777Z'
							fill='#AD310B'
						/>
						<path
							d='M54.875 33.3817L56.1696 33.5917C56.3404 33.6187 56.5159 33.5765 56.6556 33.4704L57.6993 32.6766C58.1571 32.33 58.8016 32.7172 58.7115 33.2844L58.5014 34.579C58.4745 34.7498 58.5167 34.9253 58.6228 35.065L59.4165 36.1087C59.7632 36.5665 59.376 37.211 58.8088 37.1209L57.5142 36.9108C57.3433 36.8839 57.1679 36.9261 57.0282 37.0322L55.9845 37.8259C55.5267 38.1726 54.8821 37.7854 54.9723 37.2182L55.1823 35.9236C55.2093 35.7527 55.1671 35.5773 55.061 35.4376L54.2672 34.3939C53.9206 33.9361 54.3078 33.2915 54.875 33.3817Z'
							fill='#AD310B'
						/>
						<path
							d='M33.8529 22.7734L35.0015 22.1415C35.1524 22.0586 35.2666 21.9147 35.3089 21.7459L35.6446 20.4787C35.7913 19.9246 36.5404 19.8332 36.8173 20.3345L37.4493 21.483C37.5322 21.6339 37.676 21.7481 37.8448 21.7904L39.112 22.1261C39.6661 22.2728 39.7575 23.0219 39.2563 23.2988L38.1077 23.9308C37.9568 24.0137 37.8427 24.1576 37.8003 24.3263L37.4647 25.5936C37.3179 26.1476 36.5688 26.239 36.2919 25.7378L35.6599 24.5892C35.577 24.4383 35.4332 24.3242 35.2644 24.2819L33.9972 23.9462C33.4431 23.7994 33.3517 23.0504 33.8529 22.7734Z'
							fill='#AD310B'
						/>
						<path
							d='M48.9367 9.27144L50.5753 7.91145C50.7917 7.73221 50.9274 7.47032 50.9451 7.18688L51.0887 5.0617C51.1532 4.13172 52.3161 3.75564 52.9108 4.473L54.2707 6.11159C54.45 6.32806 54.7119 6.46375 54.9953 6.48142L57.1205 6.62502C58.0505 6.68953 58.4265 7.85245 57.7092 8.44708L56.0706 9.80707C55.8541 9.98631 55.7184 10.2482 55.7007 10.5316L55.5572 12.6568C55.4927 13.5868 54.3297 13.9628 53.7351 13.2455L52.3751 11.6069C52.1959 11.3904 51.934 11.2547 51.6506 11.2371L49.5254 11.0935C48.5954 11.029 48.2193 9.86607 48.9367 9.27144Z'
							fill='#AD310B'
						/>
						<path
							d='M29.3 1.40728L29.9304 1.51929C30.0148 1.53564 30.0997 1.51355 30.1685 1.46338L30.6825 1.08469C30.9074 0.918044 31.2196 1.11294 31.1693 1.38831L31.0573 2.01875C31.0409 2.10317 31.0631 2.18802 31.1132 2.25686L31.4919 2.77081C31.6586 2.99579 31.4637 3.30795 31.1883 3.25768L30.5579 3.14563C30.4734 3.12928 30.3886 3.1514 30.3197 3.20157L29.8058 3.58027C29.5808 3.74691 29.2686 3.55202 29.3189 3.27664L29.431 2.64621C29.4473 2.56178 29.4252 2.47694 29.375 2.4081L28.9963 1.89415C28.8297 1.66917 29.0246 1.35701 29.3 1.40728Z'
							fill='#AD310B'
						/>
						<path
							d='M68.7531 15.2183L69.3835 15.3303C69.4679 15.3467 69.5528 15.3246 69.6217 15.2744L70.1356 14.8957C70.3606 14.7291 70.6727 14.924 70.6225 15.1994L70.5104 15.8298C70.4941 15.9142 70.5162 15.9991 70.5663 16.0679L70.9451 16.5818C71.1117 16.8068 70.9168 17.119 70.6414 17.0687L70.011 16.9567C69.9266 16.9403 69.8417 16.9624 69.7729 17.0126L69.2589 17.3913C69.0339 17.558 68.7218 17.363 68.772 17.0877L68.8841 16.4573C68.9004 16.3728 68.8783 16.288 68.8282 16.2191L68.4495 15.7052C68.2828 15.4802 68.4777 15.168 68.7531 15.2183Z'
							fill='#AD310B'
						/>
						<path
							d='M0.905446 12.252L1.53586 12.364C1.62028 12.3804 1.70515 12.3583 1.77399 12.3081L2.28793 11.9294C2.5129 11.7628 2.82507 11.9577 2.7748 12.2331L2.66277 12.8635C2.64642 12.9479 2.66852 13.0328 2.71869 13.1016L3.0974 13.6155C3.26404 13.8405 3.06912 14.1527 2.79375 14.1024L2.16333 13.9904C2.07891 13.974 1.99405 13.9961 1.92521 14.0463L1.41128 14.425C1.1863 14.5916 0.87412 14.3967 0.924391 14.1214L1.03643 13.4909C1.05278 13.4065 1.03067 13.3217 0.980505 13.2528L0.601804 12.7389C0.435162 12.5139 0.630074 12.2017 0.905446 12.252Z'
							fill='#AD310B'
						/>
					</g>
				</svg>
			</div>
		</div>
	);
};

export default HolidayBannerStars;
