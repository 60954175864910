import PrimaryButton from '@features/common/components/PrimaryButton';
import { getCdnStaticUrl } from '@features/common/store/common.selectors';
import Image from 'next/image';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const HeaderSection = () => {
	const { formatMessage } = useIntl();
	const cdnStaticUrl = useSelector(getCdnStaticUrl);

	return (
		<section className='w-full relative'>
			<div className='aspect-w-9 aspect-h-10 s:aspect-h-6 s:aspect-w-8  sm:aspect-w-9 sm:aspect-h-6 lg:aspect-w-13 lg:aspect-h-6 xl:aspect-w-15 xl:aspect-h-5 1xl:aspect-w-16 1xl:aspect-h-7'>
				<Image
					src={`${cdnStaticUrl}/header-bg.jpg`}
					layout='fill'
					objectFit='cover'
					quality={100}
					priority={true}
					unoptimized={true}
					alt='header background'
				/>
			</div>
			<div
				className='absolute inset-0 flex flex-col justify-center items-center px-2 space-y-8 1xl:space-y-12 text-white md:-mt-13 1xl:-mt-20'
				style={{
					fontFamily: 'Montserrat',
				}}
			>
				<h1 className='headerTitleText text-[28px] leading-[34px] md:text-[52px] 1xl:text-[56px] md:leading-[62px] 1xl:leading-[66px] md:tracking-[-3px] font-bold text-center'>
					<span className='hidden md:block'>
						{formatMessage({ id: 'bcs-HeaderSection-PlayQuizzes' }, { br: ' ' })}
					</span>
					<span className='block md:hidden'>
						{formatMessage({ id: 'bcs-HeaderSection-PlayQuizzes' }, { br: <br /> })}
					</span>
				</h1>
				<ul className='flex flex-col justify-center items-center space-y-3 1xl:space-y-4 font-semibold text-xsm md:text-[18px] px-5 xs:px-0 text-center'>
					<li>{formatMessage({ id: 'bcs-HeaderSection-CreateAccount' })}</li>
					<li>{formatMessage({ id: 'bcs-HeaderSection-PickAQuiz' })}</li>
					<li>{formatMessage({ id: 'bcs-HeaderSection-HaveFun' })}</li>
				</ul>
				<div className='flex w-full justify-center xs:space-x-3 space-x-[0.3125rem] items-center lg:w-88 pb-4'>
					<Link href={'/register'}>
						<a className='block min-w-[160px] md:min-w-[170px]'>
							<PrimaryButton type='button' testId='headerSectionRegisterLink' tabIndex={3}>
								{formatMessage({ id: 'bcs-Header-SignUp' })}
							</PrimaryButton>
						</a>
					</Link>
					<Link href={'/how-gamy-works'} passHref>
						<a
							data-testid='headerSectionHowItWorksLink'
							className='w-40 h-[3.625rem] md:w-[170px] rounded-2xl py-[16px] md:py-[18px] transition-colors font-bold xs:text-base text-sm leading-[20px] border-white text-[#11142D] bg-white focus:bg-gray-200 hover:bg-gray-200 text-center flex justify-center items-center'
							style={{
								fontFamily: "'Inter', sans-serif",
							}}
						>
							{formatMessage({ id: 'bcs-Header-HowGamyWorks' })}
						</a>
					</Link>
				</div>
			</div>
			<div className='absolute bottom-0 left-0 right-0 flex justify-center mb-2 1xl:mb-6 animate-bounce'>
				<Link href={'#header-tournaments'} scroll={true}>
					<a>
						<svg
							width='20'
							height='40'
							className='w-[10px] h-[20px] sm:w-4 sm:h-8 md:w-5 md:h-10'
							viewBox='0 0 20 40'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M9.85709 0C4.41286 0 0 4.41316 0 9.85709V19.2193C0 23.261 2.47473 26.7668 5.98017 28.2927V25.117C4.08284 23.8385 2.80438 21.6938 2.80438 19.2193V9.85717C2.80438 5.98025 5.98017 2.80446 9.85709 2.80446C13.7751 2.80446 16.9098 5.98025 16.9098 9.85717V19.2193C16.9098 21.6941 15.6313 23.8385 13.734 25.117V28.2927C17.2398 26.7668 19.7142 23.3022 19.7142 19.2193V9.85709C19.7142 4.41316 15.3013 0 9.85709 0Z'
								fill='white'
							/>
							<path
								d='M9.85727 12.4581C10.641 12.4581 11.2595 11.8396 11.2595 11.0559V7.26157C11.2595 6.47787 10.6409 5.85938 9.85727 5.85938C9.07361 5.85938 8.45508 6.4779 8.45508 7.26157V11.0559C8.45508 11.7982 9.07361 12.4581 9.85727 12.4581Z'
								fill='white'
							/>
							<path
								d='M13.6103 32.086L11.2593 34.437V22.1053C11.2593 21.3216 10.6408 20.7031 9.85714 20.7031C9.07348 20.7031 8.45495 21.3217 8.45495 22.1053V34.3958L6.10395 32.0448C5.5677 31.5085 4.66029 31.5085 4.12435 32.0448C3.58811 32.581 3.58811 33.4884 4.12435 34.0243L8.86716 38.7671C9.156 39.056 9.48569 39.1797 9.85707 39.1797C10.2282 39.1797 10.5582 39.056 10.847 38.7671L15.5898 34.0243C16.126 33.4881 16.126 32.5807 15.5898 32.0448C15.0124 31.5088 14.1461 31.5088 13.6102 32.0862L13.6103 32.086Z'
								fill='#FF652E'
							/>
						</svg>
					</a>
				</Link>
			</div>
		</section>
	);
};

export default HeaderSection;
