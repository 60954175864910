import React from 'react';
import { useIntl } from 'react-intl';
import { IMerchantHeaderProps } from '../models/merchants-header.model';

const MerchantHeader = (props: IMerchantHeaderProps) => {
	const { merchantsLength } = props;
	const { formatMessage } = useIntl();

	return (
		<div
			className='mb-5 mt-5 flex flex-col text-center'
			style={{
				width: 'fit-content',
			}}
		>
			<h1
				className='sm:text-3xl text-xsbase sm:font-bold font-medium not-italic text-dark-800 mt-10 sm:mt-13'
				style={{
					fontFamily: 'Montserrat',
					letterSpacing: '-1px',
				}}
				data-testid='ourPartners'
			>
				{formatMessage({ id: 'bcs-Merchants-Header-Our-Partners' })}
			</h1>
			{merchantsLength > 0 ? (
				<p
					className='text-grayBlue-700 not-italic text-2xsbase font-normal sm:mt-[15px] mt-5 sm:mx-0 mx-5'
					style={{
						fontFamily: 'Roboto',
					}}
					data-testid='merchantsSectionTitle'
				>
					{formatMessage({ id: 'bcs-Merchants-Description' })}
				</p>
			) : (
				<></>
			)}
		</div>
	);
};

export default MerchantHeader;
