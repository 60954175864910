import TournamentsListing from '@features/tournaments/components/TournamentsListing';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { emitHttpChampionshipsPlayNowAwardsFetchAll } from '../store/championships.actions';
import {
	getChampionshipsLoading,
	getChampionshipsPlayNowAwardsAll,
	getChampionshipsPlayNowAwardsAllCount,
} from '../store/championships.selectors';
import { mapPlayNowChampionshipToTournament } from '../utils/mapChampionshipToTournament';

const PlayNowAwardTournamentsListing = () => {
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();
	const playNowChampionshipsAwards = useSelector(getChampionshipsPlayNowAwardsAll);
	const totalPlayNowAwardsTournaments = useSelector(getChampionshipsPlayNowAwardsAllCount);
	const isLoadingTournamentsLoadMore = useSelector(getChampionshipsLoading('championshipsPlayNowAwardsFetchAll'));
	const [isRequestPlayNowAllSent, setIsRequestPlayNowAllSent] = useState(false);
	const initialTakeSizeRef = useRef(8);
	const [takeSize, setTakeSize] = useState(initialTakeSizeRef.current);

	useEffect(() => {
		if (!isLoadingTournamentsLoadMore && !isRequestPlayNowAllSent) {
			dispatch(emitHttpChampionshipsPlayNowAwardsFetchAll({ skip: 0, take: takeSize }));
			setIsRequestPlayNowAllSent(true);
		}
	}, [dispatch, isLoadingTournamentsLoadMore, isRequestPlayNowAllSent, takeSize]);

	const loadMorePlayNowAllTournaments = useCallback(() => {
		if (!isLoadingTournamentsLoadMore && totalPlayNowAwardsTournaments !== null) {
			dispatch(
				emitHttpChampionshipsPlayNowAwardsFetchAll({ skip: 0, take: takeSize + initialTakeSizeRef.current })
			)
				.then(unwrapResult)
				.then(() => setTakeSize((prevState) => prevState + initialTakeSizeRef.current));
		}
	}, [dispatch, isLoadingTournamentsLoadMore, takeSize, totalPlayNowAwardsTournaments]);

	if (!totalPlayNowAwardsTournaments) {
		return null;
	}

	return (
		<div className='mx-auto sm:w-auto w-full' id='playNowAwardsTournaments'>
			<TournamentsListing
				tournamentTitle={formatMessage({
					id: 'bcs-Tournaments-Play-Now-With-AwardsTitle',
				})}
				tournaments={mapPlayNowChampionshipToTournament(playNowChampionshipsAwards)}
				totalTournaments={totalPlayNowAwardsTournaments}
				isTournamentsLoading={isLoadingTournamentsLoadMore}
				skip={takeSize}
				loadMore={loadMorePlayNowAllTournaments}
				typeTournament={'defaultTournaments'}
				showTooltip={true}
			/>
		</div>
	);
};

export default PlayNowAwardTournamentsListing;
