import React from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { getCdnStaticUrl } from '../store/common.selectors';

interface IIconNotFoundProps {
	width?: string;
	height?: string;
}

const IconNotFound = ({ width, height }: IIconNotFoundProps) => {
	const cdnStaticUrl = useSelector(getCdnStaticUrl);

	return (
		<div className='flex h-full w-full items-center justify-center'>
			<div className={`${width ? width : 'w-36 md:w-44'} ${height ? height : 'h-36 md:h-44'}`}>
				<Image
					alt='not-found'
					src={`${cdnStaticUrl}/pageNotFound.jpg`}
					objectFit='fill'
					quality={100}
					unoptimized={true}
					layout={'responsive'}
					width='100%'
					height='100%'
				/>
			</div>
		</div>
	);
};

export default IconNotFound;
