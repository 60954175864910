import useGoogleAnalytics from '@hooks/useGoogleAnalytics';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useFacebookPixel from '@hooks/useFacebookPixel';

type ISocialRegisterType = 'GOOGLE' | 'FACEBOOK';

const SocialRegistrationEvents = () => {
	const { query } = useRouter();
	const { sendGoogleAnalyticsEvent } = useGoogleAnalytics();
	const { sendFacebookPixelEvent } = useFacebookPixel();

	const queryValue = Array.isArray(query.register) ? query.register[0].toUpperCase() : query.register?.toUpperCase();

	const match = queryValue?.match(/(facebook|google)/i);

	useEffect(() => {
		if (match) {
			switch (match[0] as ISocialRegisterType) {
				case 'FACEBOOK':
					sendGoogleAnalyticsEvent({
						eventName: 'register_facebook',
						params: { eventLabel: 'Register with facebook', eventCategory: 'register_facebook' },
					});
					sendFacebookPixelEvent({
						eventName: 'register_facebook',
						params: { eventLabel: 'Register with facebook', eventCategory: 'register_facebook' },
					});
					break;
				case 'GOOGLE':
					sendGoogleAnalyticsEvent({
						eventName: 'register_google',
						params: { eventLabel: 'Register with google', eventCategory: 'register_google' },
					});
					sendFacebookPixelEvent({
						eventName: 'register_google',
						params: { eventLabel: 'Register with google', eventCategory: 'register_google' },
					});
					break;
			}
		}
	}, [match, sendFacebookPixelEvent, sendGoogleAnalyticsEvent]);

	return null;
};

export default SocialRegistrationEvents;
