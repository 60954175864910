import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { emitHttpFetchTournamentsPageable } from '../store/tournaments.actions';
import {
	getAllTournaments,
	getTotalCountTournaments,
	getTournamentsLoadingStates,
} from '../store/tournaments.selectors';
import TournamentsListing from './TournamentsListing';

const TournamentsAllListing = () => {
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();
	const tournaments = useSelector(getAllTournaments);
	const totalTournaments = useSelector(getTotalCountTournaments);
	const isLoadingTournamentsLoadMore = useSelector(getTournamentsLoadingStates('fetchTournaments'));

	const initialTakeSizeRef = useRef(8);
	const [takeSize, setTakeSize] = useState(initialTakeSizeRef.current * 2);

	const loadMoreAllTournaments = useCallback(() => {
		if (!isLoadingTournamentsLoadMore && totalTournaments !== null) {
			dispatch(emitHttpFetchTournamentsPageable({ skip: 0, take: takeSize }))
				.then(unwrapResult)
				.then(() => setTakeSize((prevState) => prevState + initialTakeSizeRef.current));
		}
	}, [dispatch, isLoadingTournamentsLoadMore, takeSize, totalTournaments]);

	return (
		<TournamentsListing
			tournamentTitle={formatMessage({
				id: 'bcs-Tournaments-ListingTitle',
			})}
			tournaments={tournaments}
			totalTournaments={totalTournaments}
			isTournamentsLoading={isLoadingTournamentsLoadMore}
			skip={takeSize - initialTakeSizeRef.current}
			loadMore={loadMoreAllTournaments}
			typeTournament={'defaultTournaments'}
		/>
	);
};

export default TournamentsAllListing;
