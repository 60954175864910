import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { verifyRegistration } from '../store/auth.actions';
import { updateVerificationStatus } from '../store/auth.reducer';

const RegistrationComplete = () => {
	const dispatch = useAppDispatch();
	const router = useRouter();
	const { formatMessage } = useIntl();
	const [token, setToken] = useState<string | null>(null);

	useEffect(() => {
		if (!router.query.token) {
			return;
		}

		setToken(router.query.token as string);
	}, [router.query.token]);

	useEffect(() => {
		if (!token) {
			return;
		}

		dispatch(verifyRegistration(token))
			.then(unwrapResult)
			.then((res) => {
				dispatch(updateVerificationStatus('Success'));
			})
			.catch((error) => {
				dispatch(updateVerificationStatus('Failed'));
			})
			.finally(() => {
				router.push('/login-email');
			});
	}, [dispatch, formatMessage, router, token]);

	return null;
};

export default RegistrationComplete;
