import TournamentsWrapper from '@features/tournaments/components/TournamentsWrapper';
import Head from 'next/head';
import RegistrationComplete from './registration-complete';
import SocialRegistrationEvents from '@features/auth/components/SocialRegistrationEvents';

const Home = () => {
	return (
		<>
			<Head>
				<title>Gamy.fun</title>
				<link rel='icon' href='/favicon.ico' />
				<meta name='apple-mobile-web-app-capable' content='yes' />
				<meta name='apple-mobile-web-app-status-bar-style' content='black' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
				/>
				<meta property='og:title' content='Gamy.fun' key='ogTitle' />
			</Head>
			<RegistrationComplete />
			<SocialRegistrationEvents />
			<TournamentsWrapper />
		</>
	);
};

export default Home;
