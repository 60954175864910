import RegistrationComplete from '@features/auth/components/RegistrationComplete';
import { logoutUser } from '@features/auth/store/auth.actions';
import { getCurrentUser } from '@features/auth/store/auth.selectors';
import SummerBanner from '@features/banners/components/SummerBanner';
import ChampionShipsSection from '@features/championships/components/ChampionshipsSection';
import PlayNowAwardTournamentsListing from '@features/championships/components/PlayNowAwardTournamentsListing';
import PlayNowTournamentsAllListing from '@features/championships/components/PlayNowTournamentsAllListing';
import {
	emitFinishGameCountOnHomepage,
	emitHttpChampionshipsOneTimeFetchAll,
} from '@features/championships/store/championships.actions';
import { emitChampionshipRemoveAll } from '@features/championships/store/championships.reducer';
import {
	getChampionshipsLoading,
	getChampionshipsOneTimeAll,
	getChampionshipsPlayNowAwardsAllCount,
	getWSSocketType,
} from '@features/championships/store/championships.selectors';
import ChampionshipModalOldCustomers from '@features/common/components/ChampionshipModalOldCustomers';
import SnowFlakesAnimation from '@features/common/components/SnowFlakesAnimation';
import SocialLoginNotificationsModal from '@features/common/components/SocialLoginNotificationsModal';
import { openGameplaySocket } from '@features/gameplay/store/gameplay.actions';
import HeaderSection from '@features/layout/components/HeaderSection';
import MerchantsListing from '@features/merchants/components/MerchantsListing';
import { fetchMerchants } from '@features/merchants/store/merchants.action';
import { emitMerchantRemoveAll } from '@features/merchants/store/merchants.reducer';
import PartnerCompanyList from '@features/partners/components/PartnerCompanyList';
import Statistics from '@features/statistics/components/Statistics';
import { emitHttpTournamentStatistics } from '@features/statistics/store/statistics.actions';
import TournamentLeaderboard from '@features/tournaments/components/leaderboard/TournamentLeaderboard';
import useListenReduxActions from '@hooks/useListenReduxActions';
import { useAppDispatch } from '@store/store';
import axios from 'axios';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
	ITournamentLeaderboardParticipant,
	ITournamentLeaderboardResponse,
} from '../models/tournament-leaderboard-participant.model';
import { emitHttpFetchTournamentsHome } from '../store/tournaments.actions';
import {
	emitTournamentsRemoveAll,
	emitTournamentsWSstatusChanged,
	emitTournamentsWSsubscribe,
} from '../store/tournaments.reducer';
import {
	getTotalCountTournaments,
	getTournamentsLoadingStates,
	getTournamentsPrizedCount,
} from '../store/tournaments.selectors';
import TournamentWrapperPlaceholder from './TournamentWrapperPlaceholder';
import TournamentsAllListing from './TournamentsAllListing';
import TournamentsPrizedListing from './TournamentsPrizedListing';
import ChristmasBanner from '@features/banners/components/ChristmasBanner';
import CommonAd from '@features/common/components/CommonAd';
import classNames from 'classnames';

const TournamentsWrapper = () => {
	const dispatch = useAppDispatch();
	const currentUser = useSelector(getCurrentUser);
	const totalTournamentsCount = useSelector(getTotalCountTournaments);
	const playNowAwardsCount = useSelector(getChampionshipsPlayNowAwardsAllCount);
	const { formatMessage } = useIntl();
	const isLoadingTournaments = useSelector(getTournamentsLoadingStates('fetchAllTournaments'));
	const isLoadingChampionships = useSelector(getChampionshipsLoading('championshipsFetchAll'));
	const lastWebsocketMessageType = useSelector(getWSSocketType);
	const [isRequestForChampionshipSent, setIsRequestForChampionshipSent] = useState(false);
	const [isRequestForTournamentsSent, setIsRequestForTournamentsSent] = useState(false);
	const [leaderboardParticipants, setLeaderboardParticipants] = useState<ITournamentLeaderboardParticipant[]>([]);
	const getTournamentsPrizedLength = useSelector(getTournamentsPrizedCount);
	const championships = useSelector(getChampionshipsOneTimeAll);
	const [currentLeaderboardParticipant, setCurrentLeaderboardParticipant] =
		useState<ITournamentLeaderboardParticipant | null>(null);
	const [isLoadingLeaderboard, setIsLoadingLeaderboard] = useState(false);
	const [endDate] = useState(new Date('2023-12-31'));
	const today = useMemo(() => new Date(), []);

	const showHolidayBanner = useMemo(() => today < endDate, [today, endDate]);

	const router = useRouter();
	const fetchParticipants = useCallback(() => {
		setIsLoadingLeaderboard(true);
		axios
			.get<ITournamentLeaderboardResponse>('/tournament-participants/leaderboard')
			.then(({ data }) => {
				setLeaderboardParticipants(data.leaderboardCustomers.result);
				setCurrentLeaderboardParticipant(data.currentLeaderboardCustomer);
			})
			.catch((error) => error)
			.finally(() => setIsLoadingLeaderboard(false));
	}, []);

	useEffect(() => {
		fetchParticipants();
	}, [fetchParticipants]);

	useEffect(() => {
		if (!isRequestForTournamentsSent) {
			setIsRequestForTournamentsSent(true);
			dispatch(emitHttpFetchTournamentsHome());
		}
	}, [dispatch, isRequestForTournamentsSent]);

	useEffect(() => {
		if (!isRequestForChampionshipSent) {
			setIsRequestForChampionshipSent(true);
			dispatch(emitHttpChampionshipsOneTimeFetchAll());
		}
	}, [dispatch, isRequestForChampionshipSent]);

	useEffect(() => {
		dispatch(openGameplaySocket());
		dispatch(emitTournamentsWSsubscribe());
		dispatch(fetchMerchants());
		dispatch(emitHttpTournamentStatistics());
	}, [dispatch]);

	useListenReduxActions((action) => {
		if (action.type === logoutUser.fulfilled.type) {
			setIsRequestForTournamentsSent(false);
			setIsRequestForChampionshipSent(false);
		}
	});

	useListenReduxActions((action) => {
		if (action.type === emitTournamentsWSstatusChanged.type) {
			const newTournamentStatus = (action as ReturnType<typeof emitTournamentsWSstatusChanged>).payload.data
				.status;
			const newTournamentData = (action as ReturnType<typeof emitTournamentsWSstatusChanged>).payload.data
				.tournaments;
			if (newTournamentStatus === 'FINISHED') {
				fetchParticipants();
				dispatch(emitFinishGameCountOnHomepage(newTournamentData));
			}
		}
	});

	useEffect(() => {
		if (lastWebsocketMessageType === 'NEW_CHAMPIONSHIP') {
			dispatch(emitHttpChampionshipsOneTimeFetchAll());
		}
	}, [dispatch, lastWebsocketMessageType]);

	useEffect(() => {
		return () => {
			dispatch(emitTournamentsRemoveAll());
			dispatch(emitChampionshipRemoveAll());
			dispatch(emitMerchantRemoveAll());
		};
	}, [dispatch]);

	const getShouldRenderPlaceholder = useCallback(() => {
		return (
			isLoadingTournaments ||
			!isRequestForTournamentsSent ||
			isLoadingChampionships ||
			!isRequestForChampionshipSent ||
			(isLoadingLeaderboard && !leaderboardParticipants.length)
		);
	}, [
		isLoadingChampionships,
		isLoadingLeaderboard,
		isLoadingTournaments,
		isRequestForChampionshipSent,
		isRequestForTournamentsSent,
		leaderboardParticipants.length,
	]);

	const getShouldScroll = useCallback(() => {
		return (
			isLoadingTournaments ||
			!isRequestForTournamentsSent ||
			isLoadingChampionships ||
			!isRequestForChampionshipSent
		);
	}, [isLoadingChampionships, isLoadingTournaments, isRequestForChampionshipSent, isRequestForTournamentsSent]);

	const setHash = useCallback((hash: string) => {
		const targetElement = document.querySelector(hash);

		if (targetElement) {
			const y = targetElement.getBoundingClientRect().top + window.pageYOffset;
			window.scrollTo({ top: y, behavior: 'smooth' });
		}
	}, []);

	useEffect(() => {
		if (!getShouldScroll()) {
			let hash = window.location.hash;
			if (hash) {
				const element = document.getElementById('scrollOnGamys');
				if (element && element.id === hash.split('#')[1]) {
					setHash(hash);
				}
			}
		}
	}, [getShouldScroll, router, setHash]);

	const shouldHaveMarginAbovePlayNow = (): boolean => {
		if (
			(getTournamentsPrizedLength && getTournamentsPrizedLength > 0 && !championships.length) ||
			(!championships.length && !getTournamentsPrizedLength && !playNowAwardsCount)
		) {
			return false;
		}

		return true;
	};

	return (
		<>
			{showHolidayBanner ? <SnowFlakesAnimation count={13} color={'#BFDBF7'} /> : null}
			<SocialLoginNotificationsModal />
			<ChampionshipModalOldCustomers />
			{router?.query && router.query.token ? <RegistrationComplete /> : null}
			<div className='flex flex-col items-center flex-1'>
				{getShouldRenderPlaceholder() ? (
					<TournamentWrapperPlaceholder />
				) : (
					<div className='mt-18 flex flex-col w-full h-full'>
						{!currentUser ? <HeaderSection /> : null}
						<div
							className='w-full h-auto pt-6 md:pb-10'
							style={{
								background: 'linear-gradient(180deg, #FFFFFF 0%, #DAE2E8 90%)',
								zIndex: '1',
							}}
							id='header-tournaments'
						>
							<div className='flex flex-col md:flex-row justify-center'>
								<Statistics />
								<TournamentLeaderboard
									leaderboardParticipants={leaderboardParticipants}
									currentLeaderboardParticipant={currentLeaderboardParticipant}
								/>
							</div>
						</div>

						<CommonAd
							id='googleAds'
							className={classNames('w-full mx-auto sm:mt-[1.875rem] mt-5', {
								'sm:mb-0 mb-[1.875rem]':
									!playNowAwardsCount && getTournamentsPrizedLength && getTournamentsPrizedLength > 0,
								'mb-[1.875rem]':
									!playNowAwardsCount && !getTournamentsPrizedLength && !championships.length,
							})}
							type='longHorizontal'
						/>

						{showHolidayBanner ? (
							<ChristmasBanner
								description={formatMessage({ id: 'bcs-Christmas-Banner-Description' }, { br: <br /> })}
								title={formatMessage({ id: 'bcs-Christmas-Banner-Title' })}
								buttonText={formatMessage({ id: 'bcs-Christmas-Banner-Button' })}
								buttonTextLogged={''}
							/>
						) : null}
						<div id='scrollOnGamys' />
						<PlayNowAwardTournamentsListing />

						{getTournamentsPrizedLength && getTournamentsPrizedLength > 0 ? (
							<div
								className={classnames(
									'mx-auto sm:w-auto w-full sm:mb-0 tournaments-listing-all-gamys',
									{
										'mb-[30px]': championships.length > 0,
									}
								)}
								id='prized-tournaments'
							>
								<TournamentsPrizedListing />
							</div>
						) : null}

						{championships.length > 0 ? (
							<div className='mx-auto championshipSection' id='championshipId'>
								<div className='px-4 sm:px-7 mt-[30px]'>
									<ChampionShipsSection
										type='HOMEPAGE'
										title={formatMessage({ id: 'bcs-Championship-Section-Header-Homepage' })}
										championships={championships}
									/>
								</div>
							</div>
						) : null}

						<div
							className={classnames('relative flex w-full bg-playNowBox-100', {
								'mt-[30px]': shouldHaveMarginAbovePlayNow(),
								'mt-0': !shouldHaveMarginAbovePlayNow(),
							})}
							id='playNowTournamentsAll'
						>
							<PlayNowTournamentsAllListing />
						</div>
						<CommonAd className={classNames('w-full mx-auto sm:mt-10 mt-5')} type='longHorizontal' />
						{totalTournamentsCount && totalTournamentsCount > 0 ? (
							<div className={classnames('mx-auto sm:w-auto w-full')} id='all-gamys-tournaments'>
								<TournamentsAllListing />
							</div>
						) : null}

						<div className='mx-auto w-full'>
							<MerchantsListing />
						</div>
						<div className='mx-auto w-full'>
							<PartnerCompanyList />
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default TournamentsWrapper;
