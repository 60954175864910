import IconNotFound from '@features/common/components/IconNotFound';
import TournamentLeaderboardParticipant from '@features/tournaments/components/leaderboard/TournamentLeaderboardParticipant';
import { ITournamentLeaderboardParticipant } from '@features/tournaments/models/tournament-leaderboard-participant.model';
import { motion } from 'framer-motion';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

interface ITournamentLeaderboardProps {
	leaderboardParticipants: ITournamentLeaderboardParticipant[];
	currentLeaderboardParticipant: ITournamentLeaderboardParticipant | null;
}

const TournamentLeaderboardWrapper = ({ children }: Record<'children', ReactNode>) => {
	const { formatMessage } = useIntl();

	return (
		<div className='flex flex-col items-center md:items-start p-4 xl:px-[22px] lg:pr-[22px] lg:pl-0 md:pr-2.5'>
			<h1 className='text-xl text-left my-4 w-full'>
				{formatMessage({ id: 'bcs-Tournaments-WeeklyLeaderboard' })}
			</h1>
			<div
				className='shadow-lg rounded-3xl flex flex-col justify-start transition-shadow overflow-hidden bg-white w-full md:w-[16.313rem] h-[429px]'
				data-testid='weeklyLeaderboard'
			>
				{children}
			</div>
		</div>
	);
};

const TournamentLeaderboard = (props: ITournamentLeaderboardProps) => {
	const { leaderboardParticipants, currentLeaderboardParticipant } = props;
	const { formatMessage } = useIntl();
	const [animationDuration] = useState(1);

	const getShouldShowOnlyTopTen = () => {
		if (currentLeaderboardParticipant && leaderboardParticipants) {
			return leaderboardParticipants
				.map((participant) => participant.nickname)
				.includes(currentLeaderboardParticipant.nickname);
		}
		return true;
	};

	if (!leaderboardParticipants.length) {
		return (
			<TournamentLeaderboardWrapper>
				<div className='flex flex-col h-full items-center'>
					<div className='w-2/3 mx-auto mb-4 mt-14'>
						<IconNotFound width={'w-32'} height={'h-32'} />
					</div>
					<div className='text-base font-medium text-center'>
						<p>{formatMessage({ id: 'bcs-Tournaments-NoParticipantsInLeaderboard' })}</p>
						<p>{formatMessage({ id: 'bcs-Tournaments-PlayToRank' })}</p>
					</div>
				</div>
			</TournamentLeaderboardWrapper>
		);
	}

	return (
		<TournamentLeaderboardWrapper>
			{getShouldShowOnlyTopTen() ? (
				<div className='flex flex-col flex-1 py-6 px-[10px] text-sm font-bold'>
					{leaderboardParticipants.map((participant) => (
						<motion.div
							className='flex items-center mb-5'
							layout={true}
							data-testid='leaderboardParticipant'
							data-participant-nickname={participant.nickname}
							transition={{ duration: animationDuration }}
							key={participant.nickname}
							exit={{ dur: 0 }}
							animate={{ dur: 0, x: '0%' }}
							initial={{ dur: 0 }}
						>
							<TournamentLeaderboardParticipant participant={participant} />
						</motion.div>
					))}{' '}
				</div>
			) : (
				<div className='flex flex-col flex-1 py-6 px-[10px] justify-between text-sm font-bold'>
					{leaderboardParticipants.slice(0, 8).map((participant) => (
						<motion.div
							className='flex items-center'
							layout={true}
							data-testid='leaderboardParticipant'
							data-participant-nickname={participant.nickname}
							transition={{ duration: animationDuration }}
							key={participant.nickname}
							exit={{ dur: 0 }}
							animate={{ dur: 0, x: '0%' }}
							initial={{ dur: 0 }}
						>
							<TournamentLeaderboardParticipant participant={participant} />
						</motion.div>
					))}{' '}
					{currentLeaderboardParticipant ? (
						<>
							<div className='w-[44px] flex items-center justify-center'>...</div>
							<motion.div
								className='flex items-center'
								data-testid='currentLeaderboardParticipant'
								layout={true}
								transition={{ duration: animationDuration }}
								key={currentLeaderboardParticipant.nickname}
								exit={{ dur: 0 }}
								animate={{ dur: 0, x: '0%' }}
								initial={{ dur: 0 }}
							>
								<TournamentLeaderboardParticipant participant={currentLeaderboardParticipant} />
							</motion.div>
						</>
					) : null}
				</div>
			)}
		</TournamentLeaderboardWrapper>
	);
};

export default TournamentLeaderboard;
