import { getCdnStaticUrl } from '@features/common/store/common.selectors';
import classNames from 'classnames/dedupe';
import Image from 'next/image';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
	getAwardsCount,
	getAwardsTotalValue,
	getGamesWithAwardsCount,
	getStatisticsLoading,
	getWinnersCount,
} from '../store/statistics.selectors';
import StatisticsPlaceholder from './StatisticsPlaceholder';

interface ITextStyle {
	fontFamily: string;
	letterSpacing: string;
}

interface IStatisticsProps {
	translationKey: string;
	value: string | number;
	svgIconName: string;
	index: number;
	textStyle: ITextStyle;
}

const StatisticsInformation = (props: IStatisticsProps) => {
	const cdnStatic = useSelector(getCdnStaticUrl);
	const { formatMessage } = useIntl();
	const { svgIconName, translationKey, value, index, textStyle } = props;

	return (
		<div
			className={classNames(`stats-container relative`, {
				'xl:mr-[42px] lg:w-[300px] lg:min-w-[300px]': translationKey === 'bcs-Statistics-Awards-Value',
			})}
			data-testid={`${svgIconName}`}
		>
			<div className={`absolute floatingIcon-${index + 1}`} style={{ zIndex: '-1' }}>
				<div className='h-full scale-[100%]'>
					<Image
						alt={`${svgIconName}`}
						unoptimized={true}
						src={`${cdnStatic}/${svgIconName}.svg`}
						priority={true}
						layout='fill'
					/>
				</div>
			</div>

			<p className='stats' style={textStyle} data-testid='awardsStatisticsNumber'>
				{value}
			</p>

			<p
				className={classNames('font-normal text-center lg:text-lg text-base sm:h-[72px]', {
					'sm:w-[80%]': translationKey === 'bcs-Statistics-Awards-Games-With-Awards',
				})}
				style={textStyle}
				data-testid='awardsStatisticsText'
			>
				{formatMessage({ id: translationKey })}
			</p>
		</div>
	);
};

const Statistics = () => {
	const { formatMessage } = useIntl();

	const awardsCount = useSelector(getAwardsCount);
	const awardsTotalValue = useSelector(getAwardsTotalValue);
	const gamesWithAwardsCount = useSelector(getGamesWithAwardsCount);
	const winnerCount = useSelector(getWinnersCount);

	const isLoadingStatistics = useSelector(getStatisticsLoading('fetchStatistics'));

	const textStyle = {
		fontFamily: 'Montserrat',
		letterSpacing: '-1px',
	};

	const awardValue = formatMessage({ id: 'bcs-Statistics-Awards-Given-Value' }, { value: awardsTotalValue });

	const stats = [
		{ translationKey: 'bcs-Statistics-Awards-Given', value: awardsCount, svgIconName: 'Triangle_Given' },
		{ translationKey: 'bcs-Statistics-Awards-Value', value: awardValue, svgIconName: 'Square_Value' },
		{
			translationKey: 'bcs-Statistics-Awards-Games-With-Awards',
			value: gamesWithAwardsCount,
			svgIconName: 'Circle_Awards',
		},
		{ translationKey: 'bcs-Statistics-Awards-Winners', value: winnerCount, svgIconName: 'Hexagon_Winners' },
	];

	if (isLoadingStatistics) {
		return <StatisticsPlaceholder />;
	}

	return (
		<div className='flex flex-col items-center justify-center sm:mb-[77px] mb-[34px] sm:mt-[70px] mt-[30px]'>
			<div
				className='text-center md:text-3xl sm:text-1.5xl text-2xsbase text-dark-800 font-bold px-5'
				style={textStyle}
				data-testid='awardsStatisticsTitle'
			>
				<p>{formatMessage({ id: 'bcs-Statistics-Awards-Title' })}</p>
			</div>
			<div className='1xl:flex flex-col 1xl:flex-row justify-center items-center w-full px-5 md:hidden flex'>
				{stats.map(({ translationKey, value, svgIconName }, index) => {
					return (
						<StatisticsInformation
							key={translationKey}
							index={index}
							svgIconName={svgIconName}
							textStyle={textStyle}
							translationKey={translationKey}
							value={value}
						/>
					);
				})}
			</div>
			<div className='md:flex px-5 1xl:hidden hidden'>
				<div className='md:flex md:flex-col md:justify-center md:items-center w-auto lg:min-w-[360px] lg:w-[360px] md:min-w-[220px] md:w-[220px]'>
					{stats.slice(0, 2).map(({ translationKey, value, svgIconName }, index) => {
						return (
							<StatisticsInformation
								key={translationKey}
								index={index}
								svgIconName={svgIconName}
								textStyle={textStyle}
								translationKey={translationKey}
								value={value}
							/>
						);
					})}
				</div>
				<div className='md:flex md:flex-col md:justify-center md:items-center w-auto lg:min-w-[360px] lg:w-[360px] md:min-w-[220px] md:w-[220px]'>
					{stats.slice(2, 4).map(({ translationKey, value, svgIconName }, index) => {
						return (
							<StatisticsInformation
								key={index}
								index={index + 2}
								svgIconName={svgIconName}
								textStyle={textStyle}
								translationKey={translationKey}
								value={value}
							/>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Statistics;
