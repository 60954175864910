import { ITournamentCompact } from '@features/tournaments/models/tournament.model';
import { IChampionship } from '../models/championship-model';

export const mapPlayNowChampionshipToTournament = (playNowChampionships: IChampionship[]): ITournamentCompact[] => {
	const statusMap: Record<IChampionship['statusId'], ITournamentCompact['statusId']> = {
		UPCOMING: 'UNKNOWN',
		PUBLISHED: 'ACTIVE',
		RUNNING: 'RUNNING',
		FINISHED_VISIBLE: 'FINISHED',
		FINISHED_HIDDEN: 'FINISHED',
		UNKNOWN: 'UNKNOWN',
	};
	return playNowChampionships.map((tournament) => {
		return {
			registered: !!tournament.gameTemplateResource?.registered,
			name: tournament.translation.name,
			description: tournament.translation.description,
			statusId: statusMap[tournament.statusId],
			battleTypeId: '',
			entranceTypeId: 'FREE',
			awardName: tournament.awardName,
			id: tournament.id,
			clientId: tournament.clientId,
			merchant: {
				name: tournament.merchantName,
				profileImageId: tournament.merchantProfileImageId,
				id: tournament.id,
				email: '',
				phone: '',
				websiteUrl: '',
				status: '',
				translation: {
					locale: '',
					description: '',
				},
				translations: [
					{
						locale: '',
						description: '',
					},
				],
				address: '',
				clientId: '',
				displayOnHomepage: false,
			},
			logoId: tournament.imageId,
			roundsCount: 0,
			playsPerRound: tournament.gameTemplateResource ? tournament.gameTemplateResource.playsPerRound : 0,
			answerTimeMillis: tournament.gameTemplateResource ? tournament.gameTemplateResource.answerTimeMillis : 0,
			tournamentType: tournament.gameTemplateResource
				? tournament.gameTemplateResource.tournamentType
				: 'UNRECOGNIZED',
			registrationStartDate: tournament.startDate,
			registrationEndDate: tournament.endDate,
			tournamentStartDate: tournament.startDate,
			participantsCount: tournament.finishedGamesCount,
			tournamentPriority: 0,
			maxParticipantsLimit: tournament.gameTemplateResource
				? tournament.gameTemplateResource.maxParticipantsLimit
				: 0,
			championshipId: null,
		};
	});
};
