import { useAppDispatch } from '@store/store';
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import InputCheckbox from './InputCheckbox';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '@features/auth/store/auth.selectors';
import { useForm } from 'react-hook-form';
import PrimaryButton from './PrimaryButton';
import { toggleChampionshipNotificationsModal } from '../store/common.reducer';
import { parseError } from '@http/httpHelpers';
import { unwrapResult } from '@reduxjs/toolkit';
import { changeSpecialNotifications } from '@features/auth/store/auth.actions';
import { SpecialNotification } from '@features/auth/models/special-notification.model';
import { updateChampionshipStatusModal } from '@features/auth/store/auth.reducer';
const modalAlert = withReactContent(Swal);

const ChampionshipModalOldCustomers = () => {
	const dispatch = useAppDispatch();
	const { formatMessage } = useIntl();
	const isLoadingRef = useRef(false);

	const currentUser = useSelector(getCurrentUser);

	const { register, handleSubmit, formState, setError } = useForm<Pick<SpecialNotification, 'notificationNewChampionshipsWithRewards'>>({
		defaultValues: {
			notificationNewChampionshipsWithRewards: true
		},
		mode: 'onBlur',
	});

	const onSubmit = useCallback((params: Pick<SpecialNotification, 'notificationNewChampionshipsWithRewards'>) => {

		isLoadingRef.current = true;

		dispatch(changeSpecialNotifications(params))
			.then(unwrapResult)
			.then(() => {
				dispatch(toggleChampionshipNotificationsModal(false));
				dispatch(updateChampionshipStatusModal(false));
				modalAlert.close();
			})
			.catch((error) => {
				const parsedError = parseError(error);
				setError('notificationNewChampionshipsWithRewards', {
					message: parsedError.message,
				});
			})
			.finally(() => {
				isLoadingRef.current = false;
			});
	}, [dispatch, setError]);

	const openModal = useCallback(() => {
		modalAlert.fire({
			html: (
				<>
					<div className='flex justify-start'>
						<p
							className='font-semibold text-[26px] text-dark-500 text-left'
							style={{ fontFamily: "'Montserrat', normal" }}
						>
							{formatMessage({ id: 'bcs-Auth-Notification-Title' })}
						</p>
					</div>
					<div className='text-left mt-[31px] text-dark-500 text-msm' style={{ fontFamily: 'Roboto' }}>
						<p>{formatMessage({ id: 'bcs-Championship-Notification-Old-Customers-Description' })}</p>
					</div>
					<form
						className='flex flex-col items-center justify-center mt-7'
						data-testid='form'
						onSubmit={handleSubmit(onSubmit)}
					>
						<div className='mb-4 mt-2 w-full text-left'>
							<InputCheckbox
								testId={'notificationNewChampionshipsWithRewards'}
								id={'notificationNewChampionshipsWithRewards'}
								label={formatMessage({ id: 'bcs-Championship-InputBox-Text' })}
								tabIndex={1}
								fieldError={formState.errors.notificationNewChampionshipsWithRewards}
								showError={true}
								hookRegister={register('notificationNewChampionshipsWithRewards')}
							/>
						</div>

						<PrimaryButton
							type='submit'
							disabled={isLoadingRef.current}
							text={formatMessage({ id: 'bcs-Auth-Continue' })}
							tabIndex={2}
						/>
					</form>
				</>
			),
			showCancelButton: false,
			showCloseButton: false,
			showDenyButton: false,
			heightAuto: false,
			timer: undefined,
			position: 'center',
			showConfirmButton: false,
			allowOutsideClick: false,
			allowEscapeKey: false,
			customClass: {
				popup: 'alert-border-radius mt-[4%] !min-h-[305px]',
			},
			padding: '0px 0px 15px',
		});
	}, [
		formState.errors.notificationNewChampionshipsWithRewards,
		formatMessage,
		handleSubmit,
		onSubmit,
		register,
	]);

	useEffect(() => {
		if (
			currentUser?.notificationNewGamysWithRewards !== null &&
			currentUser?.notificationNewChampionshipsWithRewards === null
		) {
			dispatch(toggleChampionshipNotificationsModal(true));
			openModal();
		}
	}, [
		currentUser?.notificationNewChampionshipsWithRewards,
		currentUser?.notificationNewGamysWithRewards,
		dispatch,
		openModal,
	]);

	return null;
};

export default ChampionshipModalOldCustomers;
