import TournamentsListing from '@features/tournaments/components/TournamentsListing';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from '@store/store';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
	getChampionshipsLoading,
	getChampionshipsPlayNowAll,
	getChampionshipsPlayNowAllCount,
} from '../store/championships.selectors';
import Image from 'next/image';
import { emitHttpChampionshipsPlayNowFetchAll } from '../store/championships.actions';
import { IChampionship } from '../models/championship-model';
import { mapPlayNowChampionshipToTournament } from '../utils/mapChampionshipToTournament';
import { getCdnStaticUrl } from '@features/common/store/common.selectors';

const PlayNowTournamentsAllListing = () => {
	const { formatMessage } = useIntl();
	const dispatch = useAppDispatch();
	const playNowChampionships: IChampionship[] = useSelector(getChampionshipsPlayNowAll);
	const totalPlayNowTournaments = useSelector(getChampionshipsPlayNowAllCount);
	const cdnStatic = useSelector(getCdnStaticUrl);
	const isLoadingTournamentsLoadMore = useSelector(getChampionshipsLoading('championshipsPlayNowFetchAll'));
	const [isRequestPlayNowAllSent, setIsRequestPlayNowAllSent] = useState(false);
	const initialTakeSizeRef = useRef(8);
	const [takeSize, setTakeSize] = useState(initialTakeSizeRef.current);

	useEffect(() => {
		if (!isLoadingTournamentsLoadMore && !isRequestPlayNowAllSent) {
			dispatch(emitHttpChampionshipsPlayNowFetchAll({ skip: 0, take: takeSize }));
			setIsRequestPlayNowAllSent(true);
		}
	}, [dispatch, isLoadingTournamentsLoadMore, isRequestPlayNowAllSent, takeSize]);

	const loadMorePlayNowAllTournaments = useCallback(() => {
		if (!isLoadingTournamentsLoadMore && totalPlayNowTournaments !== null) {
			dispatch(emitHttpChampionshipsPlayNowFetchAll({ skip: 0, take: takeSize + initialTakeSizeRef.current }))
				.then(unwrapResult)
				.then(() => setTakeSize((prevState) => prevState + initialTakeSizeRef.current));
		}
	}, [dispatch, isLoadingTournamentsLoadMore, takeSize, totalPlayNowTournaments]);

	if (!totalPlayNowTournaments) {
		return null;
	}

	return (
		<>
			<div className='mx-auto sm:w-auto w-full text-white'>
				<TournamentsListing
					tournamentTitle={formatMessage({
						id: 'bcs-Tournaments-Play-NowTitle',
					})}
					tournaments={mapPlayNowChampionshipToTournament(playNowChampionships)}
					totalTournaments={totalPlayNowTournaments}
					isTournamentsLoading={isLoadingTournamentsLoadMore}
					skip={takeSize}
					loadMore={loadMorePlayNowAllTournaments}
					typeTournament={'defaultTournaments'}
					isPlayNowAllSection={true}
					showTooltip={true}
				/>
			</div>

			<div className='absolute sm:w-[249px] sm:h-[238px] h-[120px] w-[126px]'>
				<Image
					alt={'leftPlayNowIcon'}
					unoptimized={true}
					src={`${cdnStatic}/LeftPlayNowIcon.svg`}
					priority={true}
					layout='fill'
				/>
			</div>
			<div className='absolute bottom-0 right-0 sm:w-[330px] sm:h-[237px] w-[152px] h-[110px]'>
				<Image
					alt={'rightPlayNowIcon'}
					unoptimized={true}
					src={`${cdnStatic}/RightPlayNowIcon.svg`}
					priority={true}
					layout='fill'
				/>
			</div>
		</>
	);
};

export default PlayNowTournamentsAllListing;
