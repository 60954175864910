import ChampionshipCard from './ChampionshipCard';
import { IChampionship } from '../models/championship-model';
import classNames from 'classnames/dedupe';
import LoadingIndicator from '@features/layout/components/LoadingIndicator';
import { useIntl } from 'react-intl';

interface IChampionShipsSectionProps {
	title: string;
	type: 'HOMEPAGE' | 'MERCHANT_PAGE';
	championships: IChampionship[];
	skip?: number;
	take?: number;
	loadMore?: () => void;
	totalChampionships?: number | null;
	isChampionshipLoading?: boolean;
}

const ChampionShipsSection = (props: IChampionShipsSectionProps) => {
	const { type, title, championships, loadMore, skip, take, isChampionshipLoading, totalChampionships } = props;
	const { formatMessage } = useIntl();

	if (championships.length === 0) {
		return null;
	}

	return (
		<section>
			<h2
				className={classNames(`text-left lg:mt-[15px] lg:mb-[30px] mb-5 ml-0 self-start`, {
					'text-xl': type === 'HOMEPAGE',
					'font-bold text-dark-700 sm:text-1.5xl text-xlg': type !== 'HOMEPAGE',
				})}
				data-testid='championshipTitleSection'
				style={{ fontFamily: 'Montserrat' }}
			>
				{title}
			</h2>
			<div
				className={classNames(`hidden md:grid md:gap-7 gap-5 grid-cols-[repeat(1,100%)] xl:grid-cols-2`, {
					'w-max': type === 'MERCHANT_PAGE',
				})}
			>
				{championships.map((championship) => (
					<ChampionshipCard championship={championship} key={championship.id} />
				))}
			</div>
			<div className='md:hidden w-full sm:w-[554px] overflow-x-auto overflow-hidden'>
				<div className='flex space-x-5 w-max'>
					{championships.map((championship) => (
						<ChampionshipCard championship={championship} key={championship.id} />
					))}
				</div>
			</div>

			{!isChampionshipLoading && totalChampionships && skip && totalChampionships > skip ? (
				<div className='relative flex w-full justify-center h-20 my-[30px] items-center'>
					<button
						type='button'
						className='absolute py-4 px-6 h-14 bg-brightGray-200 text-brightGray-400 text-mxsm transition-colors
								font-bold rounded-2xl 
								focus:outline-none hover:bg-main-500 hover:text-white disabled:cursor-default disabled:bg-gray-200 disabled:text-black min-w-[132px] z-10'
						onClick={loadMore}
						data-testid='loadMoreBtn'
					>
						{formatMessage({
							id: 'bcs-Common-LoadMore',
						})}
					</button>
				</div>
			) : null}
			{isChampionshipLoading && totalChampionships !== null ? (
				<div className='flex w-full h-full items-center justify-center'>
					<LoadingIndicator contained={true} />
				</div>
			) : null}
		</section>
	);
};

export default ChampionShipsSection;
