import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import environmentBasedConfig from '@utils/config';

type ILayoutCommonAd = 'square' | 'longVertical' | 'longHorizontal';

interface ICommonAdProps {
	id?: string;
	className: string;
	type: ILayoutCommonAd;
}

const CommonAd = (props: ICommonAdProps) => {
	const showGoogleAds = environmentBasedConfig('SHOW_GOOGLE_ADS', window.location.host);
	const googleAdSenseKey = environmentBasedConfig('GOOGLE_ADS_SENSE_KEY', window.location.host);
	const { className, type, id } = props;
	const [height, setHeight] = useState(0);
	const [width, setWidth] = useState(0);
	const [slotId, setSlotId] = useState('');
	const [adsReadyToShow, setAdsReadyToShow] = useState<boolean>(false);
	const router = useRouter();

	useEffect(() => {
		if (!googleAdSenseKey) {
			return;
		}

		try {
			if (adsReadyToShow && slotId && width > 0 && height > 0) {
				((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
			}
		} catch (err) {
			console.error(err);
		}
	}, [adsReadyToShow, googleAdSenseKey, height, slotId, width]);

	const handleMatchMediaResize = useCallback(() => {
		if (!type) {
			return;
		}

		const desktopSize = window.matchMedia('(min-width: 1281px)');
		const mobileSize = window.matchMedia('(max-width: 767px)');

		switch (type) {
			case 'longHorizontal': {
				if (desktopSize.matches) {
					setWidth(970);
					setHeight(90);
					setSlotId('6761613188');
				} else if (mobileSize.matches) {
					setWidth(320);
					setHeight(50);
					setSlotId('5136173347');
				} else {
					setWidth(728);
					setHeight(90);
					setSlotId('2398329369');
				}
				break;
			}
			case 'square': {
				setWidth(300);
				setHeight(250);
				setSlotId('6481878759');
				break;
			}
			case 'longVertical': {
				setWidth(160);
				setHeight(600);
				setSlotId('7194843553');
				break;
			}
			default:
				break;
		}
		setAdsReadyToShow(true);
	}, [type]);

	useEffect(() => {
		handleMatchMediaResize();

		window.addEventListener('resize', handleMatchMediaResize);

		return () => {
			window.removeEventListener('resize', handleMatchMediaResize);
		};
	}, [handleMatchMediaResize]);

	if (!adsReadyToShow) {
		return null;
	}

	if (!showGoogleAds) {
		return (
			<div
				className={classNames(`${className}`, {
					'border-dashed border-2 border-[#eb934c]': !showGoogleAds,
				})}
				style={{ display: 'block', width: `${width}px`, height: `${height}px` }}
			/>
		);
	}

	return (
		<ins
			key={router.asPath.replace(/\//g, '-') + '-' + slotId + '-' + type}
			id={id}
			className={`adsbygoogle ${className} overflow-hidden`}
			style={{ display: 'block', width: `${width}px`, height: `${height}px` }}
			data-ad-client={googleAdSenseKey}
			data-ad-slot={slotId}
		/>
	);
};

export default CommonAd;
