import { getCdnUrl } from '@features/common/store/common.selectors';
import { IMerchant } from '@features/tournaments/models/tournament-merchant.model';
import Link from 'next/link';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Image from 'next/image';

const MerchantListingCard = ({ merchant }: Record<'merchant', IMerchant>) => {
	const { formatMessage } = useIntl();

	const cdnUrl = useSelector(getCdnUrl);

	return (
		<div className='relative' data-testid='merchantBox'>
			<div
				className='flex flex-col min-w-[250px] h-[297px] rounded-[24px] transition-shadow bg-white'
				style={{ scrollSnapAlign: 'center' }}
			>
				<div className='w-[100px] h-[100px] mt-[10px] mb-5 flex self-center relative'>
					<Image
						unoptimized={true}
						src={`${cdnUrl}files/${merchant.profileImageId}.jpeg`}
						priority={true}
						layout='fill'
						objectFit='contain'
						alt={merchant.name}
						data-testid='merchantLogo'
					/>
				</div>
				<div className='w-[220px] h-[61px] mx-auto'>
					<p
						className='text-sm font-normal text-left px-2.5 line-clamp-3 text-grayBlue-700'
						style={{
							fontFamily: 'Roboto',
						}}
						data-testid='merchantDescription'
					>
						{merchant.translation.description}
					</p>
				</div>

				<Link href={`/organizer/${merchant.id}`}>
					<a
						data-testid='viewMerchantButton'
						className='flex flex-col justify-center items-center mt-auto mx-auto mb-6 w-[97px] h-[56px] text-brightGray-400 rounded-[16px] transition-colors bg-[#F2F2F2] hover:bg-main-500 text-[15px] hover:text-white font-bold'
					>
						<p>{formatMessage({ id: 'bcs-Merchants-View-More' })}</p>
					</a>
				</Link>
			</div>
			<div className='mix-blend-multiply blur-xlg z-[-10] absolute bottom-[-8px] left-1 min-w-[250px] h-[300px] bg-[#F0F3F6]' />
		</div>
	);
};

export default MerchantListingCard;
