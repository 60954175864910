
import { GetServerSideProps } from 'next';

export const getServerSideProps: GetServerSideProps = async (params) => {
	return {
		redirect: {
			destination: `/?token=${params.query.token}`,
			permanent: false,
		},
	};
};

const RegistrationCompletePage = () => {
	return null;
};

export default RegistrationCompletePage;
