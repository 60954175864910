import PrimaryButton from '@features/common/components/PrimaryButton';
import { getCdnUrl } from '@features/common/store/common.selectors';
import useFormatDateTime from '@hooks/useFormatDateTime';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { IChampionship } from '../models/championship-model';
import { getSelectedLanguage } from '@features/layout/store/layout.selectors';

interface IChampionshipCardProps {
	championship: IChampionship;
}

let gradientDef =
	'linear-gradient(90deg, rgba(17, 20, 45, 0.7) 12.34%, rgba(17, 20, 45, 0.697446) 30.12%, rgba(17, 20, 45, 0.689981) 42.57%, rgba(17, 20, 45, 0.655919) 51.46%, rgba(17, 20, 45, 0.561458) 64.49%, rgba(17, 20, 45, 0) 126.13%)';

const ChampionshipCard = ({ championship }: IChampionshipCardProps) => {
	const { formatMessage } = useIntl();
	const { formatDate } = useFormatDateTime();
	const cdnUrl = useSelector(getCdnUrl);
	const router = useRouter();
	const selectedLanguageFromState = useSelector(getSelectedLanguage);

	const getBlockBackgroundStyles = (imageUrl: string) => {
		return {
			background: `url(${imageUrl}), no-repeat ${gradientDef}`,
			backgroundSize: 'cover',
		};
	};

	const onClickViewButton = () => {
		router.push(`/championships/${championship.id}/view`);
	};

	return (
		<div
			className='grid-col-2 relative championship-background-dark md:w-[34.75rem] h-64 s:w-[20rem] xs:w-[17.5rem] w-[15rem] md:h-[17.375rem] overflow-hidden rounded-3xl'
			style={getBlockBackgroundStyles(`${cdnUrl}files/${championship.imageId}.jpeg`)}
		>
			<div
				data-testid='championship'
				className='w-full h-full p-10 z-10 pt-[1.875rem] px-[1.875rem] pb-[0.9375rem] md:pb-[1.5625rem] flex flex-col text-white'
			>
				<p className='flex space-x-1 mb-2.5 text-xsm'>
					<span>
						{formatDate(
							championship.startDate,
							{ month: 'short', day: '2-digit' },
							selectedLanguageFromState
						)}
					</span>
					<span>-</span>
					<span>
						{formatDate(
							championship.endDate,
							{ month: 'short', day: '2-digit' },
							selectedLanguageFromState
						)}
					</span>
				</p>
				<p className='mb-2 text-2xsbase leading-[1.3125rem] md:text-[1.375rem] md:leading-[1.625rem] font-medium line-clamp-2'>
					{championship.translation.name}
				</p>
				<Link href={`/organizer/${championship.merchantId}`} passHref>
					<a className='flex space-x-2.5 items-center'>
						<div className='min-w-[1.25rem] min-h-[1.25rem] relative rounded-full overflow-hidden bg-white'>
							<Image
								unoptimized={true}
								src={`${cdnUrl}files/${championship.merchantProfileImageId}.jpeg`}
								objectFit='contain'
								layout='fill'
								priority={true}
								alt={championship.merchantName}
							/>
						</div>
						<p title={championship.merchantName} className='font-medium text-xsm md:leading-6 truncate'>
							{championship.merchantName}
						</p>
					</a>
				</Link>
				<div className='flex space-x-5 mb-[1.4375rem] md:mb-[1.625rem] leading-4 mt-[0.9375rem]'>
					<p className='flex space-x-2 items-center'>
						<span>
							<svg
								width='14'
								height='16'
								viewBox='0 0 14 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M9.40657 13.841C9.37524 13.7106 9.30053 13.5946 9.19486 13.5121C9.08919 13.4295 8.95866 13.3852 8.82459 13.3863H7.75625V10.9062C7.39039 10.9987 7.01506 11.0476 6.6378 11.0518C6.26068 11.0502 5.88505 11.0029 5.51935 10.9108V13.3886H4.44871C4.31463 13.3875 4.1841 13.4318 4.07844 13.5144C3.97278 13.5969 3.89818 13.7129 3.86685 13.8433L3.45533 15.5459C3.4391 15.6145 3.45559 15.6868 3.50011 15.7416C3.54464 15.7964 3.61212 15.8274 3.68265 15.8255H9.59297C9.66351 15.8274 9.73099 15.7964 9.77552 15.7416C9.82004 15.6868 9.83653 15.6145 9.82029 15.5459L9.40657 13.841Z'
									fill='white'
								/>
								<path
									d='M13.0526 2.58758H10.909V1.05544C10.909 0.995189 10.885 0.937346 10.8424 0.894723C10.7998 0.852101 10.742 0.828125 10.6817 0.828125H2.5982C2.47274 0.828125 2.37088 0.92986 2.37088 1.05544V2.58758H0.22732C0.101735 2.58758 0 2.68932 0 2.8149V4.89491C0.00380566 5.59171 0.272099 6.26098 0.750708 6.76738C1.22931 7.27378 1.88237 7.5795 2.57778 7.62275C2.9487 8.77711 3.79329 9.719 4.90034 10.2133C6.00739 10.7077 7.27262 10.7077 8.37963 10.2133C9.48668 9.71897 10.3313 8.77708 10.7022 7.62275C11.3976 7.5795 12.0506 7.27378 12.5293 6.76738C13.0079 6.26098 13.2762 5.59171 13.28 4.89491V2.8149C13.28 2.75465 13.2561 2.6968 13.2134 2.65418C13.1707 2.61156 13.113 2.58758 13.0526 2.58758H13.0526ZM0.45451 4.89491V3.04222H2.37088L2.37076 6.327C2.37101 6.6095 2.39917 6.89124 2.45486 7.16814C1.90215 7.09685 1.39438 6.8269 1.02625 6.40854C0.658121 5.99017 0.454897 5.4522 0.454539 4.89494L0.45451 4.89491ZM8.81534 5.07682L7.89235 5.9861L8.11967 7.25906C8.13629 7.34608 8.10077 7.43475 8.02872 7.48638C7.99003 7.51543 7.94297 7.53128 7.89464 7.53179C7.85176 7.52976 7.81054 7.51467 7.77641 7.48866L6.63981 6.88854L5.50321 7.48866C5.42964 7.5238 5.34287 7.51682 5.27589 7.4704C5.20384 7.41889 5.16845 7.3301 5.18494 7.24308L5.41226 5.97012L4.48927 5.06084H4.4894C4.43803 5.00286 4.41976 4.92269 4.44094 4.8481C4.46213 4.77364 4.51972 4.71491 4.59393 4.69258L5.87146 4.50623L6.44204 3.34921V3.34908C6.48047 3.27195 6.55925 3.22324 6.64551 3.22324C6.73164 3.22324 6.81042 3.27195 6.84898 3.34908L7.41955 4.50611L8.68566 4.69258C8.77052 4.70565 8.8408 4.76552 8.86731 4.84721C8.8937 4.92903 8.87188 5.01872 8.81074 5.07897L8.81534 5.07682ZM12.8253 4.89491H12.8251C12.8242 5.45204 12.6209 5.98978 12.2528 6.40802C11.8848 6.82625 11.3772 7.09644 10.8248 7.16811C10.8805 6.89119 10.9086 6.60946 10.9089 6.32696V3.04219H12.8253L12.8253 4.89491Z'
									fill='white'
								/>
							</svg>
						</span>
						<span>{championship.rewardsCount}</span>
					</p>
					<p className='flex space-x-2 items-center'>
						<span>
							<svg
								width='15'
								height='16'
								viewBox='0 0 15 16'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='M10.7143 1.48556C12.4867 1.48556 13.9287 2.9274 13.9287 4.6999V11.1284C13.9287 12.9007 12.4867 14.3427 10.7143 14.3427H4.28567C2.51333 14.3427 1.07133 12.9009 1.07133 11.1284V4.69973C1.07133 2.9274 2.51333 1.4854 4.28567 1.4854H10.7143V1.48556ZM10.7143 0.414062H4.28567C1.91883 0.414062 0 2.33273 0 4.69973V11.1282C0 13.4954 1.91883 15.4141 4.28567 15.4141H10.7142C13.0812 15.4141 15 13.4954 15 11.1284V4.69973C15 2.33273 13.0812 0.414062 10.7143 0.414062ZM4.28565 5.77126C4.87743 5.77126 5.35715 5.29153 5.35715 4.69976C5.35715 4.10798 4.87743 3.62826 4.28565 3.62826C3.69388 3.62826 3.21415 4.10798 3.21415 4.69976C3.21415 5.29153 3.69388 5.77126 4.28565 5.77126ZM11.7858 11.1285C11.7858 11.7202 11.3061 12.2 10.7143 12.2C10.1226 12.2 9.64282 11.7202 9.64282 11.1285C9.64282 10.5367 10.1226 10.057 10.7143 10.057C11.3061 10.057 11.7858 10.5367 11.7858 11.1285ZM10.7143 5.77126C11.3061 5.77126 11.7858 5.29153 11.7858 4.69976C11.7858 4.10798 11.3061 3.62826 10.7143 3.62826C10.1226 3.62826 9.64282 4.10798 9.64282 4.69976C9.64282 5.29153 10.1226 5.77126 10.7143 5.77126ZM5.35715 11.1285C5.35715 11.7202 4.87743 12.2 4.28565 12.2C3.69388 12.2 3.21415 11.7202 3.21415 11.1285C3.21415 10.5367 3.69388 10.057 4.28565 10.057C4.87743 10.057 5.35715 10.5367 5.35715 11.1285ZM4.28565 8.98561C4.87743 8.98561 5.35715 8.50588 5.35715 7.91411C5.35715 7.32234 4.87743 6.84261 4.28565 6.84261C3.69388 6.84261 3.21415 7.32234 3.21415 7.91411C3.21415 8.50588 3.69388 8.98561 4.28565 8.98561ZM11.7858 7.91411C11.7858 8.50588 11.3061 8.98561 10.7143 8.98561C10.1226 8.98561 9.64282 8.50588 9.64282 7.91411C9.64282 7.32234 10.1226 6.84261 10.7143 6.84261C11.3061 6.84261 11.7858 7.32234 11.7858 7.91411Z'
									fill='white'
								/>
							</svg>
						</span>
						<span>{championship.gamesCount}</span>
					</p>
				</div>
				<div className='w-40 md:w-[10.625rem] bottom-0 absolute mb-6'>
					<PrimaryButton testId='viewChampionshipButton' onClick={() => onClickViewButton()} tabIndex={0}>
						{formatMessage({ id: 'bcs-Championship-View' })}
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};

export default ChampionshipCard;
